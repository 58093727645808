import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Calendar, Tag, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner component

interface BlogPost {
  id: string;
  title: string;
  content: string;
  createdAt: any;
  category: string;
  status: 'draft' | 'published';
  image?: string;
  tags?: string[];
}

const Blog = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<string>('');

  useEffect(() => {
    console.log('Initializing blog page...');
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      console.log('Fetching blog posts...');
      const q = query(collection(db, 'blog_posts'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const postsData = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as BlogPost[];

      // Filtrer uniquement les articles publiés
      const publishedPosts = postsData.filter(post => post.status === 'published');
      console.log(`Found ${publishedPosts.length} published posts`);
      
      setPosts(publishedPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  // Extraire tous les tags uniques
  const allTags = Array.from(new Set(posts.flatMap(post => post.tags || [])));
  
  // Extraire toutes les catégories uniques
  const allCategories = Array.from(new Set(posts.map(post => post.category)));

  // Filtrer les posts selon la catégorie et le tag sélectionnés
  const filteredPosts = posts.filter(post => {
    const matchCategory = !selectedCategory || post.category === selectedCategory;
    const matchTag = !selectedTag || (post.tags && post.tags.includes(selectedTag));
    return matchCategory && matchTag;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <LoadingSpinner size="md" color="blue-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-[#E5C13B] mb-8">Notre Blog</h1>

        {/* Filtres */}
        <div className="flex flex-wrap gap-4 mb-8">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="px-4 py-2 rounded-lg bg-gray-900 text-[#E5C13B] border border-[#E5C13B] focus:border-[#E5C13B] focus:ring-1 focus:ring-[#E5C13B]"
          >
            <option value="">Toutes les catégories</option>
            {allCategories.map(category => (
              <option key={category} value={category}>
                {category.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </option>
            ))}
          </select>

          <select
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            className="px-4 py-2 rounded-lg bg-gray-900 text-[#E5C13B] border border-[#E5C13B] focus:border-[#E5C13B] focus:ring-1 focus:ring-[#E5C13B]"
          >
            <option value="">Tous les tags</option>
            {allTags.map(tag => (
              <option key={tag} value={tag}>{tag}</option>
            ))}
          </select>
        </div>

        {/* Grille d'articles */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPosts.map(post => (
            <Link
              key={post.id}
              to={`/blog/${post.id}`}
              className="group bg-gray-900 rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 border border-gray-800 hover:border-[#E5C13B]"
            >
              {post.image && (
                <div className="aspect-video w-full overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
              )}
              <div className="p-6">
                <div className="flex flex-wrap items-center gap-4 text-sm text-gray-400 mb-4">
                  <div className="flex items-center gap-1.5">
                    <Calendar className="h-4 w-4" />
                    <time>{new Date(post.createdAt.toDate()).toLocaleDateString()}</time>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <Tag className="h-4 w-4" />
                    <span>
                      {post.category.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                    </span>
                  </div>
                </div>

                <h2 className="text-xl font-semibold text-[#E5C13B] mb-2 group-hover:text-white transition-colors duration-200">
                  {post.title}
                </h2>

                {post.tags && post.tags.length > 0 && (
                  <div className="flex flex-wrap gap-2 mt-4">
                    {post.tags.map(tag => (
                      <span
                        key={tag}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-800 text-[#E5C13B]"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                )}

                <div className="mt-4 flex items-center text-[#E5C13B] group-hover:text-white transition-colors duration-200">
                  <span className="text-sm">Lire l'article</span>
                  <ChevronRight className="h-4 w-4 ml-2" />
                </div>
              </div>
            </Link>
          ))}
        </div>

        {filteredPosts.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-400 text-lg">
              Aucun article trouvé pour les filtres sélectionnés.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
