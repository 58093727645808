import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './context/ThemeContext';
import { HelmetProvider } from 'react-helmet-async';
import AuthProvider from './components/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import LocationAvecChauffeur from './pages/LocationAvecChauffeur';
import TransfertsAeroport from './pages/TransfertsAeroport';
import ServiceCorporate from './pages/ServiceCorporate';
import EvenementsSpeciaux from './pages/EvenementsSpeciaux';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';

// Pages
import Login from './pages/Login';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import AdminProfile from './pages/AdminProfile';

function App() {
  useEffect(() => {
    console.log('App component mounted');
    console.log('Current environment:', import.meta.env.MODE);
    console.log('Base URL:', import.meta.env.BASE_URL);
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <div className="min-h-screen bg-white dark:bg-dark-bg text-gray-900 dark:text-gray-100 transition-colors duration-200">
          <AuthProvider>
            <Toaster position="top-right" />
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/location-avec-chauffeur" element={<LocationAvecChauffeur />} />
                <Route path="/transferts-aeroport" element={<TransfertsAeroport />} />
                <Route path="/service-corporate" element={<ServiceCorporate />} />
                <Route path="/evenements-speciaux" element={<EvenementsSpeciaux />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogPost />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route 
                path="/admin/dashboard" 
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/admin/profile" 
                element={
                  <ProtectedRoute>
                    <AdminProfile />
                  </ProtectedRoute>
                } 
              />
            </Routes>
          </AuthProvider>
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;