import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Star, Clock, Shield, Car, Users, MapPin, CheckCircle2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function LocationAvecChauffeur() {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ block: 'start', behavior: 'instant' });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Location de Voiture avec Chauffeur à Paris | Elite Transfert</title>
        <meta name="description" content="Service de location de voiture avec chauffeur privé à Paris. Flotte premium, chauffeurs professionnels, service 24/7. Réservez votre chauffeur privé dès maintenant." />
        <meta name="keywords" content="location voiture chauffeur, chauffeur privé paris, vtc paris, location limousine, transport luxe paris" />
        <meta property="og:title" content="Location de Voiture avec Chauffeur à Paris | Elite Transfert" />
        <meta property="og:description" content="Service premium de location de voiture avec chauffeur à Paris. Flotte luxueuse, chauffeurs expérimentés, service personnalisé 24/7." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elite-transfer.fr/location-avec-chauffeur" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Location de Voiture avec Chauffeur Paris | Elite Transfert" />
        <meta name="twitter:description" content="Service haut de gamme de location de voiture avec chauffeur à Paris. Réservation facile, service 24/7, chauffeurs professionnels." />
        <link rel="canonical" href="https://elite-transfer.fr/location-avec-chauffeur" />
      </Helmet>

      <div ref={contentRef} className="min-h-screen bg-[#121212] text-white py-16 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-7xl mx-auto"
        >
          {/* En-tête */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Location de Voiture avec Chauffeur
              <span className="block text-2xl mt-2 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text">
                L'excellence au service de votre mobilité
              </span>
            </h1>
            <p className="text-gray-300 max-w-3xl mx-auto text-lg">
              Découvrez notre service de location de voiture avec chauffeur privé. Une expérience sur mesure alliant luxe, confort et professionnalisme pour tous vos déplacements.
            </p>
          </div>

          {/* Notre flotte */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold mb-8 text-center">Notre Flotte Premium</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  name: "Berline Luxe",
                  image: "/vehicles/berline.jpg",
                  features: ["4 passagers", "3 bagages", "Wifi gratuit", "Climatisation"]
                },
                {
                  name: "SUV Premium",
                  image: "/vehicles/eco.jpg",
                  features: ["6 passagers", "4 bagages", "Wifi gratuit", "Minibar"]
                },
                {
                  name: "Van VIP",
                  image: "/vehicles/van.jpg",
                  features: ["7 passagers", "7 bagages", "Wifi gratuit", "Configuration modulable"]
                }
              ].map((vehicle, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-white/5 backdrop-blur-lg rounded-xl overflow-hidden"
                >
                  <div className="h-48 overflow-hidden">
                    <img 
                      src={vehicle.image} 
                      alt={vehicle.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-4">{vehicle.name}</h3>
                    <ul className="space-y-2">
                      {vehicle.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center text-gray-300">
                          <CheckCircle2 className="w-5 h-5 text-yellow-500 mr-2" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Services inclus */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="space-y-6"
            >
              <h2 className="text-3xl font-bold mb-6">Services Inclus</h2>
              <div className="space-y-4">
                {[
                  "Chauffeur privé professionnel et multilingue",
                  "Véhicule premium récent et parfaitement entretenu",
                  "Wifi gratuit et chargeurs de téléphone",
                  "Eau minérale et rafraîchissements",
                  "Service de conciergerie disponible",
                  "Possibilité de réservation à l'heure ou à la journée"
                ].map((service, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <CheckCircle2 className="w-6 h-6 text-yellow-500 flex-shrink-0 mt-1" />
                    <p className="text-gray-300">{service}</p>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-8"
            >
              <h3 className="text-2xl font-bold mb-6">Occasions Spéciales</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Événements d'Entreprise</h4>
                  <p className="text-gray-300">Transport de vos collaborateurs et clients VIP lors de vos événements professionnels.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Mariages & Cérémonies</h4>
                  <p className="text-gray-300">Service personnalisé pour rendre votre journée encore plus spéciale.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Tourisme de Luxe</h4>
                  <p className="text-gray-300">Découvrez Paris et ses environs avec un chauffeur-guide expérimenté.</p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Avantages */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
            {[
              {
                icon: Clock,
                title: "Disponibilité 24/7",
                description: "Service disponible à toute heure"
              },
              {
                icon: Shield,
                title: "Sécurité Maximale",
                description: "Chauffeurs certifiés et assurance premium"
              },
              {
                icon: Star,
                title: "Service 5 étoiles",
                description: "Excellence et professionnalisme garantis"
              },
              {
                icon: MapPin,
                title: "Couverture Totale",
                description: "Service dans toute l'Île-de-France"
              }
            ].map((advantage, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="text-center p-6 bg-white/5 backdrop-blur-lg rounded-xl"
              >
                <advantage.icon className="w-12 h-12 text-yellow-500 mx-auto mb-4" />
                <h3 className="text-lg font-semibold mb-2">{advantage.title}</h3>
                <p className="text-gray-300">{advantage.description}</p>
              </motion.div>
            ))}
          </div>

          {/* Call to Action */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="text-center"
          >
            <h2 className="text-3xl font-bold mb-6">Réservez Votre Chauffeur</h2>
            <p className="text-gray-300 mb-8 max-w-3xl mx-auto">
              Profitez d'un service sur mesure avec nos chauffeurs professionnels. Tarifs transparents et devis personnalisés pour répondre à vos besoins.
            </p>
            <Link to="/">
              <button className="px-8 py-4 bg-yellow-500 text-black rounded-xl font-semibold hover:bg-yellow-400 transition-colors">
                Demander un Devis
              </button>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
