import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Calendar, Tag, ArrowLeft, Share2 } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface BlogPost {
  id: string;
  title: string;
  content: string;
  createdAt: any;
  category: string;
  status: 'draft' | 'published';
  image?: string;
  tags?: string[];
  textColor?: string;
  titleColor?: string;
  subtitleColor?: string;
  h1Color?: string;
}

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      console.log('Fetching blog post with ID:', id);
      if (!id) return;

      try {
        const docRef = doc(db, 'blog_posts', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log('Blog post found:', docSnap.id);
          const postData = { id: docSnap.id, ...docSnap.data() } as BlogPost;
          setPost(postData);
        } else {
          console.log('No blog post found with ID:', id);
          toast.error('Article non trouvé');
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
        toast.error('Erreur lors du chargement de l\'article');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const handleShare = async () => {
    try {
      await navigator.share({
        title: post?.title,
        text: `Découvrez cet article intéressant : ${post?.title}`,
        url: window.location.href
      });
    } catch (error) {
      console.error('Error sharing:', error);
      // Fallback : copier le lien dans le presse-papier
      navigator.clipboard.writeText(window.location.href);
      toast.success('Lien copié dans le presse-papier');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-12 text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">Article non trouvé</h1>
        <Link to="/blog" className="text-blue-600 hover:text-blue-700">
          Retourner à la liste des articles
        </Link>
      </div>
    );
  }

  return (
    <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <Link
          to="/blog"
          className="inline-flex items-center text-blue-600 hover:text-blue-700 mb-8 transition-colors duration-200"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Retour aux articles
        </Link>

        {post.image && (
          <div className="aspect-video w-full rounded-xl overflow-hidden mb-8">
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-full object-cover"
            />
          </div>
        )}

        <div className="flex flex-wrap items-center gap-4 text-sm text-gray-600 mb-4">
          <div className="flex items-center gap-1.5">
            <Calendar className="h-4 w-4" />
            <time>{new Date(post.createdAt.toDate()).toLocaleDateString()}</time>
          </div>
          <div className="flex items-center gap-1.5">
            <Tag className="h-4 w-4" />
            <span>
              {post.category.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </span>
          </div>
          <button
            onClick={handleShare}
            className="ml-auto inline-flex items-center gap-1.5 text-blue-600 hover:text-blue-700"
          >
            <Share2 className="h-4 w-4" />
            Partager
          </button>
        </div>

        <h1 className="text-4xl font-bold mb-4" style={{ color: post.h1Color || '#007bff' }}>
          {post.title}
        </h1>

        {post.tags && post.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-8">
            {post.tags.map(tag => (
              <span
                key={tag}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>

      <div 
        className="prose prose-lg max-w-none"
        dangerouslySetInnerHTML={{ __html: `
          <style>
            h1 { color: ${post.h1Color || '#007bff'} !important; }
            h2 { color: ${post.titleColor || '#111111'} !important; }
            h3 { color: ${post.subtitleColor || '#222222'} !important; }
            p { color: ${post.textColor || '#333333'}; }
            .cta-section { 
              background-color: #000000 !important;
              border: 1px solid #E5C13B !important;
            }
            .cta-section h3, .cta-section p {
              color: #E5C13B !important;
            }
            .cta-button:hover {
              background-color: #E5C13B !important;
              color: #000000 !important;
            }
          </style>
          ${post.content}
        `}}
      />
    </article>
  );
};

export default BlogPost;
