import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Plane, Clock, Shield, Car, Users, MapPin, CheckCircle2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function TransfertsAeroport() {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ block: 'start', behavior: 'instant' });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Transfert Aéroport Paris | Service VTC Aéroports | Elite Transfert</title>
        <meta name="description" content="Service de transfert aéroport premium à Paris. Navettes CDG, Orly, Beauvais. Transport VTC professionnel, ponctuel et confortable. Réservation 24/7." />
        <meta name="keywords" content="transfert aéroport paris, navette aéroport, vtc aéroport, transport cdg, transport orly, navette beauvais" />
        <meta property="og:title" content="Transfert Aéroport Paris | Service VTC Premium | Elite Transfert" />
        <meta property="og:description" content="Service de transfert aéroport haut de gamme à Paris. Navettes CDG, Orly, Beauvais avec chauffeurs professionnels. Réservation simple et rapide." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elite-transfer.fr/transferts-aeroport" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Transfert Aéroport Paris | Elite Transfert" />
        <meta name="twitter:description" content="Service VTC premium pour vos transferts aéroport à Paris. Ponctualité garantie, confort optimal, tarifs transparents." />
        <link rel="canonical" href="https://elite-transfer.fr/transferts-aeroport" />
      </Helmet>

      <div ref={contentRef} className="min-h-screen bg-[#121212] text-white py-16 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-7xl mx-auto"
        >
          {/* En-tête */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Transferts Aéroport Premium
              <span className="block text-2xl mt-2 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text">
                Voyagez en toute sérénité
              </span>
            </h1>
            <p className="text-gray-300 max-w-3xl mx-auto text-lg">
              Service de navette haut de gamme vers tous les aéroports de Paris. Une expérience de transport exclusive qui allie confort, ponctualité et professionnalisme.
            </p>
          </div>

          {/* Caractéristiques principales */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {[
              {
                icon: Clock,
                title: "Service 24/7",
                description: "Disponibilité garantie à toute heure, tous les jours de l'année"
              },
              {
                icon: Shield,
                title: "Suivi en temps réel",
                description: "Surveillance des vols et ajustement automatique du service"
              },
              {
                icon: Car,
                title: "Flotte Premium",
                description: "Véhicules haut de gamme pour un confort optimal"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-white/5 backdrop-blur-lg rounded-xl p-6 hover:bg-white/10 transition-colors"
              >
                <feature.icon className="w-12 h-12 text-yellow-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </motion.div>
            ))}
          </div>

          {/* Services détaillés */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="space-y-6"
            >
              <h2 className="text-3xl font-bold mb-6">Nos Services Aéroport</h2>
              <div className="space-y-4">
                {[
                  "Transferts vers/depuis tous les aéroports parisiens (CDG, Orly, Le Bourget)",
                  "Service de conciergerie et aide aux bagages",
                  "Accueil personnalisé avec panneau nominatif",
                  "Suivi des vols en temps réel",
                  "Véhicules premium avec wifi et rafraîchissements",
                  "Chauffeurs multilingues expérimentés"
                ].map((service, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <CheckCircle2 className="w-6 h-6 text-yellow-500 flex-shrink-0 mt-1" />
                    <p className="text-gray-300">{service}</p>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-8"
            >
              <h3 className="text-2xl font-bold mb-6">Pourquoi Nous Choisir ?</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Expertise Reconnue</h4>
                  <p className="text-gray-300">Plus de 10 ans d'expérience dans le transport premium et des milliers de clients satisfaits.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Ponctualité Garantie</h4>
                  <p className="text-gray-300">Suivi en temps réel des vols et adaptation automatique des horaires de prise en charge.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Confort Premium</h4>
                  <p className="text-gray-300">Flotte de véhicules haut de gamme régulièrement renouvelée pour votre confort.</p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Tarifs et réservation */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="text-center"
          >
            <h2 className="text-3xl font-bold mb-6">Réservez Votre Transfert</h2>
            <p className="text-gray-300 mb-8 max-w-3xl mx-auto">
              Assurez votre tranquillité d'esprit en réservant votre transfert à l'avance. Service disponible 24/7 avec suivi en temps réel.
            </p>
            <Link to="/">
              <button className="px-8 py-4 bg-yellow-500 text-black rounded-xl font-semibold hover:bg-yellow-400 transition-colors">
                Réserver un Transfert
              </button>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
