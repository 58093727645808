import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, MapPin, Calendar, Clock } from 'lucide-react';
import OptimizedImage from './OptimizedImage';
import '../styles/heroslider.css';
import { AnimatePresence } from 'framer-motion';

interface AddressSuggestion {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

interface HeroSliderProps {
  onBookNow: (formData: {
    pickup: string;
    dropoff: string;
    date: string;
    time: string;
    pickupPlaceId: string;
    dropoffPlaceId: string;
  }) => void;
}

const HeroSlider: React.FC<HeroSliderProps> = ({ onBookNow }) => {
  const [formData, setFormData] = useState({
    pickup: '',
    dropoff: '',
    date: '',
    time: '',
    pickupPlaceId: '',
    dropoffPlaceId: ''
  });

  const [pickupSuggestions, setPickupSuggestions] = useState<AddressSuggestion[]>([]);
  const [dropoffSuggestions, setDropoffSuggestions] = useState<AddressSuggestion[]>([]);
  const [showPickupSuggestions, setShowPickupSuggestions] = useState(false);
  const [showDropoffSuggestions, setShowDropoffSuggestions] = useState(false);

  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken | null>(null);

  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState('');

  useEffect(() => {
    let isLoading = false;
    const loadGoogleMapsAPI = async () => {
      if (isLoading || window.google) {
        console.log('Google Maps API déjà chargée ou en cours de chargement');
        if (window.google && !autocompleteService.current) {
          console.log('Initialisation des services Google Maps...');
          autocompleteService.current = new window.google.maps.places.AutocompleteService();
          const tempDiv = document.createElement('div');
          placesService.current = new window.google.maps.places.PlacesService(tempDiv);
          sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
          console.log('Services Google Maps initialisés avec succès');
        }
        return;
      }
      
      isLoading = true;
      console.log('Chargement de l\'API Google Maps...');
      
      try {
        await new Promise((resolve, reject) => {
          const script = document.createElement('script');
          const apiKey = process.env.VITE_GOOGLE_MAPS_API_KEY;
          console.log('Clé API disponible:', !!apiKey);
          
          script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=fr`;
          script.async = true;
          script.defer = true;
          
          script.onload = () => {
            console.log('Script Google Maps chargé');
            if (window.google) {
              console.log('Initialisation des services après chargement...');
              autocompleteService.current = new window.google.maps.places.AutocompleteService();
              const tempDiv = document.createElement('div');
              placesService.current = new window.google.maps.places.PlacesService(tempDiv);
              sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
              console.log('Services initialisés avec succès');
              resolve(true);
            } else {
              console.error('window.google non disponible après chargement');
              reject(new Error('Google Maps API not loaded'));
            }
          };
          
          script.onerror = (error) => {
            console.error('Erreur lors du chargement du script:', error);
            reject(new Error('Failed to load Google Maps API'));
          };
          
          document.head.appendChild(script);
          console.log('Script ajouté au head');
        });
      } catch (error) {
        console.error('Erreur lors du chargement de l\'API Google Maps:', error);
      } finally {
        isLoading = false;
      }
    };

    loadGoogleMapsAPI();

    // Gestionnaire de clic en dehors des suggestions
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.input-icon-wrapper')) {
        setShowPickupSuggestions(false);
        setShowDropoffSuggestions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Créer un nouveau sessionToken après chaque sélection
  const refreshSessionToken = () => {
    if (window.google) {
      sessionToken.current = new google.maps.places.AutocompleteSessionToken();
    }
  };

  const getPlacePredictions = async (input: string, type: 'pickup' | 'dropoff') => {
    console.log(`Recherche de suggestions pour ${type}:`, input);
    
    if (!input) {
      console.log('Input vide, pas de recherche');
      return;
    }
    
    if (!autocompleteService.current) {
      console.error('Service d\'autocomplétion non initialisé');
      return;
    }

    // Créer un nouveau sessionToken si nécessaire
    if (!sessionToken.current && window.google) {
      console.log('Création d\'un nouveau sessionToken');
      sessionToken.current = new google.maps.places.AutocompleteSessionToken();
    }

    try {
      console.log('Préparation de la requête avec les paramètres:', {
        input,
        sessionToken: !!sessionToken.current,
        service: !!autocompleteService.current
      });

      const request = {
        input,
        componentRestrictions: { country: 'fr' },
        sessionToken: sessionToken.current,
        types: ['establishment', 'geocode'],
        language: 'fr'
      };

      console.log('Envoi de la requête de prédiction...');
      const predictions = await new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {
        if (!autocompleteService.current) {
          console.error('Service perdu pendant la requête');
          reject(new Error('Autocomplete service not initialized'));
          return;
        }

        autocompleteService.current.getPlacePredictions(
          request,
          (results, status) => {
            console.log('Réponse reçue:', { status, resultCount: results?.length });
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              resolve(results);
            } else {
              console.error('Erreur de statut:', status);
              reject(status);
            }
          }
        );
      });

      console.log(`${predictions.length} suggestions reçues`);
      const sortedPredictions = predictions.sort((a, b) => {
        const isAirportA = a.description.toLowerCase().includes('aéroport') || 
                          a.description.toLowerCase().includes('airport');
        const isAirportB = b.description.toLowerCase().includes('aéroport') || 
                          b.description.toLowerCase().includes('airport');
        const isStationA = a.description.toLowerCase().includes('gare');
        const isStationB = b.description.toLowerCase().includes('gare');
        
        if (isAirportA && !isAirportB) return -1;
        if (!isAirportA && isAirportB) return 1;
        if (isStationA && !isStationB) return -1;
        if (!isStationA && isStationB) return 1;
        return 0;
      });

      console.log(`Mise à jour des suggestions pour ${type}`);
      if (type === 'pickup') {
        setPickupSuggestions(sortedPredictions);
        setShowPickupSuggestions(true);
      } else {
        setDropoffSuggestions(sortedPredictions);
        setShowDropoffSuggestions(true);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des suggestions:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'pickup' | 'dropoff') => {
    const { value } = e.target;
    console.log(`Input changed (${type}):`, value);
    setFormData(prev => ({ ...prev, [type]: value }));
    
    // Débounce la recherche pour éviter trop d'appels API
    if (value.length >= 2) {
      getPlacePredictions(value, type);
    } else {
      if (type === 'pickup') {
        setPickupSuggestions([]);
        setShowPickupSuggestions(false);
      } else {
        setDropoffSuggestions([]);
        setShowDropoffSuggestions(false);
      }
    }
  };

  const handleSuggestionClick = (suggestion: AddressSuggestion, type: 'pickup' | 'dropoff') => {
    setFormData(prev => ({
      ...prev,
      [type]: suggestion.structured_formatting.main_text + ' - ' + suggestion.structured_formatting.secondary_text,
      [`${type}PlaceId`]: suggestion.place_id
    }));
    if (type === 'pickup') {
      setShowPickupSuggestions(false);
    } else {
      setShowDropoffSuggestions(false);
    }
    calculateDistanceAndDuration(suggestion.place_id, type);
    refreshSessionToken();
  };

  const calculateDistanceAndDuration = async (placeId: string, type: 'pickup' | 'dropoff') => {
    if (!placesService.current) return;

    try {
      const request = {
        placeId,
        fields: ['geometry']
      };

      const result = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        placesService.current?.getDetails(
          request,
          (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              resolve(results);
            } else {
              reject(status);
            }
          }
        );
      });

      const pickupLocation = type === 'pickup' ? result.geometry.location : formData.pickupPlaceId ? await getPlaceLocation(formData.pickupPlaceId) : null;
      const dropoffLocation = type === 'dropoff' ? result.geometry.location : formData.dropoffPlaceId ? await getPlaceLocation(formData.dropoffPlaceId) : null;

      if (pickupLocation && dropoffLocation) {
        const distanceMatrixService = new window.google.maps.DistanceMatrixService();
        const request = {
          origins: [pickupLocation],
          destinations: [dropoffLocation],
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.METRIC
        };

        const result = await new Promise<google.maps.DistanceMatrixResponse>((resolve, reject) => {
          distanceMatrixService.getDistanceMatrix(
            request,
            (results, status) => {
              if (status === google.maps.DistanceMatrixStatus.OK && results) {
                resolve(results);
              } else {
                reject(status);
              }
            }
          );
        });

        const distance = result.rows[0].elements[0].distance.value;
        const duration = result.rows[0].elements[0].duration.text;
        setDistance(distance);
        setDuration(duration);
      }
    } catch (error) {
      console.error('Error calculating distance and duration:', error);
    }
  };

  const getPlaceLocation = async (placeId: string) => {
    if (!placesService.current) return null;

    try {
      const request = {
        placeId,
        fields: ['geometry']
      };

      const result = await new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
        placesService.current?.getDetails(
          request,
          (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              resolve(results);
            } else {
              reject(status);
            }
          }
        );
      });

      return result.geometry.location;
    } catch (error) {
      console.error('Error getting place location:', error);
      return null;
    }
  };

  const calculatePrice = () => {
    if (!distance) return 0;

    // Prix de base fixe à 20€
    const basePrice = 20;
    
    // Prix par kilomètre selon la distance
    let pricePerKm;
    if (distance / 1000 <= 30) {
      pricePerKm = 2.5; // Prix plus élevé pour les courtes distances
    } else if (distance / 1000 <= 100) {
      pricePerKm = 2.0; // Prix moyen pour les distances moyennes
    } else {
      pricePerKm = 1.5; // Prix réduit pour les longues distances
    }

    // Calcul du prix total
    const totalPrice = basePrice + (distance / 1000 * pricePerKm);

    // Prix minimum de 30€
    const finalPrice = Math.max(totalPrice, 30);

    // Arrondir au nombre entier supérieur
    return Math.ceil(finalPrice);
  };

  const handleQuickBook = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.pickup && formData.dropoff && formData.date && formData.time) {
      onBookNow(formData);
    }
  };

  const today = new Date().toISOString().split('T')[0];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getOptimizedImageUrl = (imageUrl: string) => {
    if (isMobile) {
      return `${imageUrl}?w=640&q=75`; // Version mobile plus légère
    }
    return `${imageUrl}?w=1920&q=85`; // Version desktop haute qualité
  };

  const renderSuggestions = (type: 'pickup' | 'dropoff') => {
    const suggestions = type === 'pickup' ? pickupSuggestions : dropoffSuggestions;
    const show = type === 'pickup' ? showPickupSuggestions : showDropoffSuggestions;

    if (!show || suggestions.length === 0) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.2 }}
        className="absolute z-50 mt-1 w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
      >
        {suggestions.length === 0 ? (
          <div className="px-4 py-3 text-sm text-gray-500 text-center">
            Aucun résultat trouvé
          </div>
        ) : (
          suggestions.map((suggestion) => (
            <motion.div
              key={suggestion.place_id}
              whileHover={{ backgroundColor: "rgba(243, 244, 246, 1)" }}
              onClick={() => handleSuggestionClick(suggestion, type)}
              className="px-4 py-3 cursor-pointer border-b last:border-b-0 border-gray-100 transition-colors duration-150"
            >
              <div className="flex items-start space-x-3">
                <div className="flex-shrink-0 mt-1">
                  <MapPin className="w-4 h-4 text-yellow-500" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {suggestion.structured_formatting.main_text}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {suggestion.structured_formatting.secondary_text}
                  </p>
                </div>
              </div>
            </motion.div>
          ))
        )}
      </motion.div>
    );
  };

  return (
    <div className="relative w-full h-screen bg-black">
      {/* Image de fond */}
      <div className="absolute inset-0">
        <OptimizedImage
          src={getOptimizedImageUrl("https://www.drive2spot.com/wp-content/uploads/2024/09/Decouvrez_le_luxe_ultime_voitures_avec_chauffeur_pour_un_voyage_inoubliable.webp")}
          alt="Elite Transfer VTC"
          className="w-full h-full object-cover"
          priority={true}
        />
        <div className="absolute inset-0 bg-black/60" />
      </div>

      {/* Contenu */}
      <div className="relative h-full flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              Service de Transport VTC Premium
            </h1>
            <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto">
              Voyagez avec style et confort. Notre service de chauffeur privé vous garantit une expérience de transport exceptionnelle.
            </p>

            {/* Formulaire de réservation rapide */}
            <motion.form
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              onSubmit={handleQuickBook}
              className="max-w-4xl mx-auto bg-white/10 backdrop-blur-md p-6 rounded-xl shadow-2xl border border-white/20"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                {/* Pickup Location */}
                <div className="relative input-icon-wrapper">
                  <input
                    type="text"
                    placeholder="Lieu de départ"
                    value={formData.pickup}
                    onChange={(e) => handleInputChange(e, 'pickup')}
                    onFocus={() => setShowPickupSuggestions(true)}
                    className="hero-input w-full bg-white/90 backdrop-blur-sm rounded-lg border border-white/30 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition-all duration-200 py-3 pl-4 pr-12"
                  />
                  <MapPin className="absolute right-4 top-3.5 h-5 w-5 text-yellow-500" />
                  <AnimatePresence>
                    {renderSuggestions('pickup')}
                  </AnimatePresence>
                </div>

                {/* Dropoff Location */}
                <div className="relative input-icon-wrapper">
                  <input
                    type="text"
                    placeholder="Lieu d'arrivée"
                    value={formData.dropoff}
                    onChange={(e) => handleInputChange(e, 'dropoff')}
                    onFocus={() => setShowDropoffSuggestions(true)}
                    className="hero-input w-full bg-white/90 backdrop-blur-sm rounded-lg border border-white/30 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition-all duration-200 py-3 pl-4 pr-12"
                  />
                  <MapPin className="absolute right-4 top-3.5 h-5 w-5 text-yellow-500" />
                  <AnimatePresence>
                    {renderSuggestions('dropoff')}
                  </AnimatePresence>
                </div>

                {/* Date */}
                <div className="relative input-icon-wrapper">
                  <input
                    type="date"
                    value={formData.date}
                    onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
                    min={today}
                    className="hero-input w-full bg-white/90 backdrop-blur-sm rounded-lg border border-white/30 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition-all duration-200 py-3 pl-4 pr-12"
                  />
                  <Calendar className="absolute right-4 top-3.5 h-5 w-5 text-yellow-500" />
                </div>

                {/* Time */}
                <div className="relative input-icon-wrapper">
                  <input
                    type="time"
                    value={formData.time}
                    onChange={(e) => setFormData(prev => ({ ...prev, time: e.target.value }))}
                    className="hero-input w-full bg-white/90 backdrop-blur-sm rounded-lg border border-white/30 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition-all duration-200 py-3 pl-4 pr-12"
                  />
                  <Clock className="absolute right-4 top-3.5 h-5 w-5 text-yellow-500" />
                </div>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="mt-6 w-full md:w-auto px-8 py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black rounded-lg font-medium hover:from-yellow-600 hover:to-yellow-700 transition-all flex items-center justify-center mx-auto group"
              >
                Réserver maintenant
                <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
              </motion.button>
            </motion.form>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
