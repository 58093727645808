import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { MessageCircle, Trash2, Check, X, User, Loader } from 'lucide-react';
import { auth } from '../../lib/firebase'; // Importer l'objet auth

interface Message {
  id: string;
  content: string;
  sender: string;
  timestamp: any;
  read: boolean;
}

interface Conversation {
  id: string;
  userId: string;
  userEmail: string;
  lastMessage: string;
  lastMessageTime: any;
  unreadCount: number;
}

const ConversationManager = () => {
  console.log('🔵 ConversationManager - Composant monté');
  
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log('🔵 ConversationManager - useEffect - Chargement des conversations');
    setIsLoading(true);
    setError(null);
    
    // Vérifier l'authentification
    const currentUser = auth.currentUser;
    console.log('🔵 ConversationManager - Utilisateur actuel:', currentUser?.uid);
    
    try {
      // Vérifier la collection
      const conversationsRef = collection(db, 'conversations');
      console.log('🔵 ConversationManager - Collection path:', conversationsRef.path);
      
      // Créer une requête simple sans orderBy pour tester
      const q = query(conversationsRef);
      console.log('🔵 ConversationManager - Query créée:', q);
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        console.log('🔵 ConversationManager - Snapshot reçu:', snapshot.size, 'conversations');
        console.log('🔵 ConversationManager - Snapshot metadata:', {
          hasPendingWrites: snapshot.metadata.hasPendingWrites,
          fromCache: snapshot.metadata.fromCache
        });
        
        if (snapshot.empty) {
          console.log('🔵 ConversationManager - Collection vide');
          // Tenter de lister tous les documents même s'ils sont vides
          snapshot.docs.forEach((doc, index) => {
            console.log(`Document ${index}:`, {
              id: doc.id,
              exists: doc.exists(),
              data: doc.data()
            });
          });
        } else {
          snapshot.docs.forEach(doc => {
            console.log('🔵 ConversationManager - Document:', {
              id: doc.id,
              data: doc.data()
            });
          });
        }
        
        const conversationsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Conversation[];
        
        console.log('🔵 ConversationManager - Données des conversations:', conversationsData);
        setConversations(conversationsData);
        setIsLoading(false);
      }, (error) => {
        console.error('🔴 ConversationManager - Erreur lors du chargement des conversations:', error);
        setError('Erreur lors du chargement des conversations');
        setIsLoading(false);
        toast.error('Erreur lors du chargement des conversations');
      });

      return () => {
        console.log('🔵 ConversationManager - Nettoyage du listener');
        unsubscribe();
      };
    } catch (error) {
      console.error('🔴 ConversationManager - Erreur dans le try/catch:', error);
      setError('Erreur lors du chargement des conversations');
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!selectedConversation) return;

    console.log('🔵 ConversationManager - Chargement des messages pour la conversation:', selectedConversation);
    try {
      const messagesRef = collection(db, `conversations/${selectedConversation}/messages`);
      const q = query(messagesRef);
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        console.log('🔵 ConversationManager - Messages reçus:', snapshot.size);
        
        // Convertir les messages et les trier manuellement
        const messagesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Message[];
        
        // Tri manuel des messages par timestamp
        const sortedMessages = messagesData.sort((a, b) => {
          const timeA = a.timestamp?.toMillis?.() || 0;
          const timeB = b.timestamp?.toMillis?.() || 0;
          return timeA - timeB;
        });

        console.log('🔵 ConversationManager - Messages triés:', sortedMessages);
        setMessages(sortedMessages);
      }, (error) => {
        console.error('🔴 ConversationManager - Erreur lors du chargement des messages:', error);
        toast.error('Erreur lors du chargement des messages');
      });

      return () => {
        console.log('🔵 ConversationManager - Nettoyage du listener de messages');
        unsubscribe();
      };
    } catch (error) {
      console.error('🔴 ConversationManager - Erreur dans le try/catch des messages:', error);
      toast.error('Erreur lors du chargement des messages');
    }
  }, [selectedConversation]);

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedConversation || !newMessage.trim()) return;

    try {
      const timestamp = new Date();
      const messageContent = newMessage.trim();

      // Ajouter le message
      const messagesRef = collection(db, `conversations/${selectedConversation}/messages`);
      await addDoc(messagesRef, {
        content: messageContent,
        sender: 'admin',
        timestamp,
        read: true
      });

      // Mettre à jour la conversation
      const conversationRef = doc(db, 'conversations', selectedConversation);
      await updateDoc(conversationRef, {
        lastMessage: messageContent,
        lastMessageTime: timestamp,
        updatedAt: timestamp,
        messagesCount: (messages.length + 1)
      });

      setNewMessage('');
      toast.success('Message envoyé');
    } catch (error) {
      console.error('🔴 ConversationManager - Erreur lors de l\'envoi du message:', error);
      toast.error('Erreur lors de l\'envoi du message');
    }
  };

  const handleDeleteConversation = async (conversationId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette conversation ?')) {
      try {
        console.log('🔵 ConversationManager - Suppression de la conversation:', conversationId);
        await deleteDoc(doc(db, 'conversations', conversationId));
        if (selectedConversation === conversationId) {
          setSelectedConversation(null);
          setMessages([]);
        }
        toast.success('Conversation supprimée');
      } catch (error) {
        console.error('🔴 ConversationManager - Erreur lors de la suppression:', error);
        toast.error('Erreur lors de la suppression');
      }
    }
  };

  console.log('🔵 ConversationManager - Rendu avec:', {
    conversations: conversations.length,
    selectedConversation,
    messages: messages.length,
    isLoading,
    error
  });

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <div className="text-red-500 mb-2">
            <X className="h-12 w-12 mx-auto" />
          </div>
          <p className="text-gray-600">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Réessayer
          </button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <Loader className="h-8 w-8 animate-spin mx-auto text-blue-500" />
          <p className="mt-2 text-gray-600">Chargement des conversations...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-2rem)] gap-4 bg-gray-50 p-4">
      {/* Liste des conversations */}
      <div className="w-full lg:w-1/3 bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-4 border-b bg-gray-50">
          <h2 className="text-lg font-semibold text-gray-800">Conversations</h2>
        </div>
        <div className="overflow-y-auto h-[300px] lg:h-full">
          {conversations.length === 0 ? (
            <div className="flex items-center justify-center h-full text-gray-500">
              <p>Aucune conversation</p>
            </div>
          ) : (
            conversations.map((conversation) => (
              <div
                key={conversation.id}
                onClick={() => setSelectedConversation(conversation.id)}
                className={`p-4 border-b cursor-pointer hover:bg-gray-50 transition-colors ${
                  selectedConversation === conversation.id ? 'bg-blue-50' : ''
                }`}
              >
                <div className="flex justify-between items-start">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center space-x-2">
                      <User className="h-5 w-5 text-gray-400 flex-shrink-0" />
                      <span className="font-medium text-gray-900 truncate">
                        {conversation.userEmail}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500 mt-1 line-clamp-1">
                      {conversation.lastMessage}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2 ml-2">
                    {conversation.unreadCount > 0 && (
                      <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                        {conversation.unreadCount}
                      </span>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConversation(conversation.id);
                      }}
                      className="text-red-500 hover:bg-red-50 p-1 rounded transition-colors"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <div className="mt-1 text-xs text-gray-400">
                  {conversation.lastMessageTime?.toDate().toLocaleString()}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Zone de chat */}
      <div className="flex-1 bg-white rounded-lg shadow-lg flex flex-col">
        {selectedConversation ? (
          <>
            <div className="p-4 border-b bg-gray-50">
              <h2 className="text-lg font-semibold text-gray-800">
                Messages
              </h2>
            </div>

            <div className="flex-1 overflow-y-auto p-4 space-y-4">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`flex ${message.sender === 'admin' ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`max-w-[70%] rounded-lg p-3 ${
                      message.sender === 'admin'
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    <p className="break-words">{message.content}</p>
                    <span className="text-xs opacity-70 mt-1 block">
                      {message.timestamp?.toDate().toLocaleString()}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <form onSubmit={sendMessage} className="p-4 border-t bg-gray-50">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Tapez votre message..."
                />
                <button
                  type="submit"
                  disabled={!newMessage.trim()}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center p-4 text-gray-500">
            <div className="text-center">
              <MessageCircle className="h-12 w-12 mx-auto mb-2 text-gray-400" />
              <p>Sélectionnez une conversation pour afficher les messages</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationManager;
