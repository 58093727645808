import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import PWAInstallPrompt from './PWAInstallPrompt';

const Layout: React.FC = () => {
  const location = useLocation();
  const hideNavbarPaths = ['/admin/dashboard'];

  // Vérifie si le chemin actuel est dans la liste des chemins où la navbar doit être cachée
  const shouldHideNavbar = hideNavbarPaths.includes(location.pathname);

  console.log('Current pathname:', location.pathname);
  console.log('Should hide navbar:', shouldHideNavbar);

  return (
    <div className="min-h-screen flex flex-col bg-[#121212]">
      {!shouldHideNavbar && <Navbar />}
      <main className="flex-grow pt-16">
        <Outlet />
      </main>
      {!shouldHideNavbar && <Footer />}
      <PWAInstallPrompt />
    </div>
  );
};

export default Layout;
