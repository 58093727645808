import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Building2, Users, Clock, Shield, Briefcase, CreditCard, CheckCircle2, Star, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function ServiceCorporate() {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ block: 'start', behavior: 'instant' });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Service Corporate VTC Paris | Transport Professionnel | Elite Transfert</title>
        <meta name="description" content="Service de transport corporate haut de gamme à Paris. Solutions sur mesure pour entreprises, événements professionnels et transport de collaborateurs. Service VTC 24/7." />
        <meta name="keywords" content="vtc entreprise paris, transport corporate, chauffeur professionnel, service vtc business, transport événementiel paris" />
        <meta property="og:title" content="Service Corporate VTC Paris | Transport Professionnel | Elite Transfert" />
        <meta property="og:description" content="Solutions de transport corporate premium à Paris. Service VTC dédié aux entreprises, événements professionnels et transport de collaborateurs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elite-transfer.fr/service-corporate" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Service Corporate VTC Paris | Elite Transfert" />
        <meta name="twitter:description" content="Transport professionnel haut de gamme pour entreprises à Paris. Solutions sur mesure, service 24/7, chauffeurs expérimentés." />
        <link rel="canonical" href="https://elite-transfer.fr/service-corporate" />
      </Helmet>

      <div ref={contentRef} className="min-h-screen bg-[#121212] text-white py-16 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-7xl mx-auto"
        >
          {/* En-tête */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Service Corporate
              <span className="block text-2xl mt-2 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text">
                Solutions de transport professionnel
              </span>
            </h1>
            <p className="text-gray-300 max-w-3xl mx-auto text-lg">
              Des solutions de transport haut de gamme adaptées aux besoins des entreprises. Optimisez vos déplacements professionnels avec notre service dédié aux entreprises.
            </p>
          </div>

          {/* Solutions Corporate */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {[
              {
                icon: Building2,
                title: "Transport d'Affaires",
                description: "Service VIP pour vos réunions et rendez-vous professionnels"
              },
              {
                icon: Users,
                title: "Événements Corporate",
                description: "Transport de groupes pour vos événements d'entreprise"
              },
              {
                icon: CreditCard,
                title: "Facturation Entreprise",
                description: "Solutions de paiement et facturation adaptées"
              }
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-white/5 backdrop-blur-lg rounded-xl p-6 hover:bg-white/10 transition-colors"
              >
                <service.icon className="w-12 h-12 text-yellow-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-300">{service.description}</p>
              </motion.div>
            ))}
          </div>

          {/* Services Détaillés */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="space-y-6"
            >
              <h2 className="text-3xl font-bold mb-6">Nos Services Corporate</h2>
              <div className="space-y-4">
                {[
                  "Transport de dirigeants et VIP",
                  "Navettes pour événements d'entreprise",
                  "Service de conciergerie dédié",
                  "Accueil personnalisé avec panneau nominatif",
                  "Facturation mensuelle centralisée",
                  "Reporting détaillé des prestations"
                ].map((service, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <CheckCircle2 className="w-6 h-6 text-yellow-500 flex-shrink-0 mt-1" />
                    <p className="text-gray-300">{service}</p>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-8"
            >
              <h3 className="text-2xl font-bold mb-6">Avantages Entreprise</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Gestion Simplifiée</h4>
                  <p className="text-gray-300">Plateforme de réservation dédiée et facturation centralisée pour une gestion optimale.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Tarifs Préférentiels</h4>
                  <p className="text-gray-300">Bénéficiez de tarifs négociés et de forfaits adaptés à vos besoins.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Service Premium</h4>
                  <p className="text-gray-300">Chauffeurs expérimentés et véhicules haut de gamme pour vos collaborateurs.</p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Statistiques */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
            {[
              {
                number: "500+",
                label: "Entreprises Partenaires"
              },
              {
                number: "50k+",
                label: "Trajets Réalisés"
              },
              {
                number: "99%",
                label: "Taux de Satisfaction"
              },
              {
                number: "24/7",
                label: "Support Dédié"
              }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="text-center p-6 bg-white/5 backdrop-blur-lg rounded-xl"
              >
                <div className="text-3xl font-bold text-yellow-500 mb-2">{stat.number}</div>
                <div className="text-gray-300">{stat.label}</div>
              </motion.div>
            ))}
          </div>

          {/* Call to Action */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="text-center space-y-6"
          >
            <h2 className="text-3xl font-bold">Commencez Aujourd'hui</h2>
            <p className="text-gray-300 max-w-3xl mx-auto">
              Optimisez la mobilité de votre entreprise avec nos solutions sur mesure.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
              <Link to="/">
                <button className="px-8 py-4 bg-yellow-500 text-black rounded-xl font-semibold hover:bg-yellow-400 transition-colors w-full sm:w-auto">
                  Demander Un Devis
                </button>
              </Link>
              <a 
                href="tel:0650288464" 
                className="px-8 py-4 bg-transparent border-2 border-yellow-500 text-yellow-500 rounded-xl font-semibold hover:bg-yellow-500 hover:text-black transition-colors w-full sm:w-auto flex items-center justify-center gap-2"
              >
                <Phone className="w-5 h-5" />
                <span>06 50 28 84 64</span>
              </a>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
