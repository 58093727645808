import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, onSnapshot, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { 
  LogOut, Search, Calendar as CalendarIcon, MessageSquare, Settings, Users, FileText, 
  Trash2, Check, X, Mail, Phone, MapPin, Clock, Car, UserPlus, Baby, Euro,
  CalendarDays, Navigation, User, Briefcase, PlusCircle, Shield, BookOpen, Menu, X as XIcon
} from 'lucide-react';
import BookingCalendar from '../components/BookingCalendar';
import ConversationManager from '../components/admin/ConversationManager';
import AdminManager from '../components/admin/AdminManager';
import BlogManager from '../components/admin/BlogManager';
import { Booking } from '../types/booking';
import { format } from 'date-fns';

interface Location {
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  } | null;
}

interface Booking {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  pickup: Location;
  dropoff: Location;
  scheduledDate: any;
  scheduledTime: string;
  status: 'pending' | 'confirmed' | 'completed' | 'cancelled';
  vehicle: {
    name: string;
    type: string;
  };
  passengers: number;
  luggage: number;
  bookingNumber: string;
  options?: {
    babySeat: boolean;
    boosterSeat: boolean;
    accessibility: boolean;
    multipleStops: boolean;
    luggageService: boolean;
  };
  price: number;
  createdAt: any;
}

const AdminDashboard = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState<'reservations' | 'calendar' | 'conversations' | 'admins' | 'blog' | 'settings'>('reservations');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const navigate = useNavigate();

  // Stats calculés
  const stats = {
    total: bookings.length,
    pending: bookings.filter(b => b.status === 'pending').length,
    confirmed: bookings.filter(b => b.status === 'confirmed').length,
    cancelled: bookings.filter(b => b.status === 'cancelled').length
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (!user || !user.email?.endsWith('@elitetransfert.com')) {
      navigate('/admin/login');
      return;
    }

    const bookingsQuery = query(collection(db, 'bookings'));
    const unsubscribe = onSnapshot(bookingsQuery, (snapshot) => {
      const bookingsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        bookingNumber: doc.id.slice(-12),
        createdAt: doc.data().createdAt
      })) as Booking[];
      setBookings(bookingsData);
      setFilteredBookings(bookingsData);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = bookings.filter(booking => 
        booking.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.phone.includes(searchTerm) ||
        booking.pickup.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.dropoff.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        booking.bookingNumber.includes(searchTerm)
      );
      setFilteredBookings(filtered);
    } else {
      setFilteredBookings(bookings);
    }
  }, [searchTerm, bookings]);

  // Trier les réservations par date de création (les plus récentes en premier)
  const sortedBookings = filteredBookings.sort((a, b) => {
    const dateA = a.createdAt?.toMillis() || 0;
    const dateB = b.createdAt?.toMillis() || 0;
    return dateB - dateA;
  });

  const handleStatusUpdate = async (bookingId: string, newStatus: 'confirmed' | 'cancelled') => {
    try {
      await updateDoc(doc(db, 'bookings', bookingId), {
        status: newStatus,
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut:', error);
    }
  };

  const handleDeleteBooking = async (bookingId: string) => {
    try {
      await deleteDoc(doc(db, 'bookings', bookingId));
    } catch (error) {
      console.error('Erreur lors de la suppression de la réservation:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/admin/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const handleDayClick = (date: Date) => {
    const selectedDateStr = format(date, 'yyyy-MM-dd');
    const dayBookings = bookings.filter(booking => booking.scheduledDate === selectedDateStr);
    console.log('Réservations pour', selectedDateStr, ':', dayBookings);
  };

  return (
    <div className="flex h-screen bg-[#0B1437] text-white relative">
      {/* Menu Burger pour mobile */}
      <button 
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-blue-900 text-white hover:bg-blue-800"
      >
        {isSidebarOpen ? <XIcon className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      {/* Overlay sombre pour mobile quand le menu est ouvert */}
      {isSidebarOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div className={`
        fixed lg:static w-64 h-screen bg-[#0B1437] border-r border-gray-800 z-40
        transition-transform duration-300 ease-in-out overflow-y-auto
        flex flex-col
        ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
      `}>
        <div className="p-4 flex items-center space-x-2">
          <span className="text-2xl">👑</span>
          <h2 className="text-xl font-semibold text-white">Administration</h2>
        </div>
        
        <nav className="mt-4 space-y-2 flex-1">
          <button
            onClick={() => {
              setActiveTab('reservations');
              setIsSidebarOpen(false);
            }}
            className={`flex items-center w-full px-4 py-2 text-left ${
              activeTab === 'reservations' ? 'bg-blue-900 text-blue-400' : 'text-white hover:bg-gray-800'
            }`}
          >
            <FileText className="w-5 h-5 mr-2" />
            Réservations
          </button>
          
          <button
            onClick={() => {
              setActiveTab('calendar');
              setIsSidebarOpen(false);
            }}
            className={`flex items-center w-full px-4 py-2 text-left ${
              activeTab === 'calendar' ? 'bg-blue-900 text-blue-400' : 'text-white hover:bg-gray-800'
            }`}
          >
            <CalendarIcon className="w-5 h-5 mr-2" />
            Calendrier
          </button>

          <button
            onClick={() => {
              setActiveTab('conversations');
              setIsSidebarOpen(false);
            }}
            className={`flex items-center w-full px-4 py-2 text-left ${
              activeTab === 'conversations' ? 'bg-blue-900 text-blue-400' : 'text-white hover:bg-gray-800'
            }`}
          >
            <MessageSquare className="w-5 h-5 mr-2" />
            Conversations
          </button>

          <button
            onClick={() => {
              setActiveTab('admins');
              setIsSidebarOpen(false);
            }}
            className={`flex items-center w-full px-4 py-2 text-left ${
              activeTab === 'admins' ? 'bg-blue-900 text-blue-400' : 'text-white hover:bg-gray-800'
            }`}
          >
            <Shield className="w-5 h-5 mr-2" />
            Administrateurs
          </button>

          <button
            onClick={() => {
              setActiveTab('blog');
              setIsSidebarOpen(false);
            }}
            className={`flex items-center w-full px-4 py-2 text-left ${
              activeTab === 'blog' ? 'bg-blue-900 text-blue-400' : 'text-white hover:bg-gray-800'
            }`}
          >
            <BookOpen className="w-5 h-5 mr-2" />
            Blog
          </button>

          <button
            onClick={() => {
              setActiveTab('settings');
              setIsSidebarOpen(false);
            }}
            className={`flex items-center w-full px-4 py-2 text-left ${
              activeTab === 'settings' ? 'bg-blue-900 text-blue-400' : 'text-white hover:bg-gray-800'
            }`}
          >
            <Settings className="w-5 h-5 mr-2" />
            Paramètres
          </button>
        </nav>

        <div className="p-4 mt-auto">
          <button
            onClick={handleLogout}
            className="flex items-center space-x-3 w-full p-3 rounded-lg text-red-400 hover:bg-red-900/20"
          >
            <LogOut className="w-5 h-5" />
            <span>Déconnexion</span>
          </button>
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 overflow-auto lg:ml-0 w-full">
        <div className="p-4 lg:p-6">
          {/* Contenu principal avec padding ajusté */}
          <div className="max-w-full mx-auto">
            {activeTab === 'reservations' && (
              <div className="p-4 md:p-6">
                {/* Stats Summary */}
                <div className="mb-8">
                  <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold dark:text-white mb-4 sm:mb-0">Résumé des Réservations</h2>
                    <button
                      onClick={() => {
                        // TODO: Implement export functionality
                        const csvContent = sortedBookings.map(booking => {
                          return `${booking.bookingNumber},${booking.firstName},${booking.lastName},${booking.email},${booking.phone},${booking.pickup.address},${booking.dropoff.address},${booking.scheduledDate},${booking.status},${booking.price}€`;
                        }).join('\n');
                        const blob = new Blob([`Numéro,Prénom,Nom,Email,Téléphone,Départ,Arrivée,Date,Statut,Prix\n${csvContent}`], { type: 'text/csv;charset=utf-8;' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'reservations.csv';
                        link.click();
                      }}
                      className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all duration-300"
                    >
                      <FileText className="h-5 w-5" />
                      Exporter CSV
                    </button>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                    <div className="bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md border border-gray-200 dark:border-gray-700">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">Total Réservations</p>
                          <p className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                            {sortedBookings.length}
                          </p>
                        </div>
                        <div className="p-3 bg-blue-100 dark:bg-blue-900 rounded-lg">
                          <FileText className="h-6 w-6 text-blue-500 dark:text-blue-400" />
                        </div>
                      </div>
                    </div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md border border-gray-200 dark:border-gray-700">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">Confirmées</p>
                          <p className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                            {sortedBookings.filter(b => b.status === 'confirmed').length}
                          </p>
                        </div>
                        <div className="p-3 bg-green-100 dark:bg-green-900 rounded-lg">
                          <Check className="h-6 w-6 text-green-500 dark:text-green-400" />
                        </div>
                      </div>
                    </div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md border border-gray-200 dark:border-gray-700">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">En Attente</p>
                          <p className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                            {sortedBookings.filter(b => b.status === 'pending').length}
                          </p>
                        </div>
                        <div className="p-3 bg-yellow-100 dark:bg-yellow-900 rounded-lg">
                          <Clock className="h-6 w-6 text-yellow-500 dark:text-yellow-400" />
                        </div>
                      </div>
                    </div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md border border-gray-200 dark:border-gray-700">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">Annulées</p>
                          <p className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                            {sortedBookings.filter(b => b.status === 'cancelled').length}
                          </p>
                        </div>
                        <div className="p-3 bg-red-100 dark:bg-red-900 rounded-lg">
                          <X className="h-6 w-6 text-red-500 dark:text-red-400" />
                        </div>
                      </div>
                    </div>

                    <div className="bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md border border-gray-200 dark:border-gray-700">
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-sm text-gray-500 dark:text-gray-400">Total CA</p>
                          <p className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                            {sortedBookings.reduce((total, b) => total + (b.price || 0), 0).toFixed(2)}€
                          </p>
                        </div>
                        <div className="p-3 bg-purple-100 dark:bg-purple-900 rounded-lg">
                          <Euro className="h-6 w-6 text-purple-500 dark:text-purple-400" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Search Bar */}
                <div className="relative w-full sm:w-72">
                  <input
                    type="text"
                    placeholder="Rechercher une réservation..."
                    className="w-full pl-10 pr-4 py-3 border rounded-xl bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Search className="absolute left-3 top-3.5 h-5 w-5 text-gray-400" />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-8">
                  {sortedBookings.map((booking) => (
                    <div
                      key={booking.id}
                      className="group bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-md hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-700 relative overflow-hidden"
                    >
                      {/* Status Badge */}
                      <div className="absolute top-0 right-0">
                        <div
                          className={`px-4 py-2 rounded-bl-2xl text-sm font-medium ${
                            booking.status === 'confirmed'
                              ? 'bg-green-500 text-white'
                              : booking.status === 'cancelled'
                              ? 'bg-red-500 text-white'
                              : 'bg-yellow-500 text-white'
                          }`}
                        >
                          {booking.status === 'confirmed' 
                            ? 'Confirmée' 
                            : booking.status === 'cancelled'
                            ? 'Annulée'
                            : 'En attente'}
                        </div>
                      </div>

                      {/* Header */}
                      <div className="mb-6">
                        <h3 className="text-xl font-bold dark:text-white mb-1 pr-24">
                          {booking.firstName} {booking.lastName}
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Réservation #{booking.bookingNumber}
                        </p>
                      </div>

                      {/* Contact Info */}
                      <div className="space-y-3 mb-6">
                        <div className="flex items-center text-gray-600 dark:text-gray-300 group-hover:text-blue-500 transition-colors">
                          <Mail className="h-5 w-5 mr-3" />
                          <span className="text-sm">{booking.email}</span>
                        </div>
                        <div className="flex items-center text-gray-600 dark:text-gray-300 group-hover:text-blue-500 transition-colors">
                          <Phone className="h-5 w-5 mr-3" />
                          <span className="text-sm">{booking.phone}</span>
                        </div>
                      </div>

                      {/* Journey Details */}
                      <div className="border-t border-gray-200 dark:border-gray-700 py-4 space-y-4">
                        <div className="flex items-start">
                          <MapPin className="h-5 w-5 mr-3 text-gray-400 mt-1 flex-shrink-0" />
                          <div>
                            <p className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Départ</p>
                            <p className="text-sm text-gray-700 dark:text-gray-300">{booking.pickup.address}</p>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <Navigation className="h-5 w-5 mr-3 text-gray-400 mt-1 flex-shrink-0" />
                          <div>
                            <p className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">Arrivée</p>
                            <p className="text-sm text-gray-700 dark:text-gray-300">{booking.dropoff.address}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <Clock className="h-5 w-5 mr-3 text-gray-400 flex-shrink-0" />
                          <div>
                            <p className="text-sm text-gray-700 dark:text-gray-300">
                              {new Date(booking.scheduledDate).toLocaleString('fr-FR', {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                              })}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Details Grid */}
                      <div className="border-t border-gray-200 dark:border-gray-700 py-4">
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                          <div className="flex items-center text-gray-600 dark:text-gray-300">
                            <Users className="h-5 w-5 mr-2 text-gray-400" />
                            <span className="text-sm">{booking.passengers} passagers</span>
                          </div>
                          {booking.luggage > 0 && (
                            <div className="flex items-center text-gray-600 dark:text-gray-300">
                              <Briefcase className="h-5 w-5 mr-2 text-gray-400" />
                              <span className="text-sm">{booking.luggage} bagages</span>
                            </div>
                          )}
                          {booking.options?.babySeat && (
                            <div className="flex items-center text-gray-600 dark:text-gray-300">
                              <Baby className="h-5 w-5 mr-2 text-gray-400" />
                              <span className="text-sm">Siège bébé</span>
                            </div>
                          )}
                          {booking.options?.multipleStops && (
                            <div className="flex items-center text-gray-600 dark:text-gray-300">
                              <MapPin className="h-5 w-5 mr-2 text-gray-400" />
                              <span className="text-sm">Arrêts multiples</span>
                            </div>
                          )}
                          {booking.options?.luggageService && (
                            <div className="flex items-center text-gray-600 dark:text-gray-300">
                              <Briefcase className="h-5 w-5 mr-2 text-gray-400" />
                              <span className="text-sm">Service bagages</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Actions */}
                      <div className="flex justify-end items-center gap-3 mt-4">
                        {booking.status === 'pending' && (
                          <>
                            <button
                              onClick={() => handleStatusUpdate(booking.id, 'confirmed')}
                              className="p-2 text-green-500 hover:text-white hover:bg-green-500 rounded-full transition-all duration-300 transform hover:scale-110 group relative"
                              title="Confirmer"
                            >
                              <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs py-1 px-2 rounded">
                                Confirmer
                              </span>
                              <Check className="h-6 w-6" />
                            </button>
                            <button
                              onClick={() => handleStatusUpdate(booking.id, 'cancelled')}
                              className="p-2 text-red-500 hover:text-white hover:bg-red-500 rounded-full transition-all duration-300 transform hover:scale-110 group relative"
                              title="Annuler"
                            >
                              <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs py-1 px-2 rounded">
                                Annuler
                              </span>
                              <X className="h-6 w-6" />
                            </button>
                          </>
                        )}
                        <button
                          onClick={() => handleDeleteBooking(booking.id)}
                          className="p-2 text-gray-500 hover:text-white hover:bg-gray-500 rounded-full transition-all duration-300 transform hover:scale-110 group relative"
                          title="Supprimer"
                        >
                          <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block bg-gray-800 text-white text-xs py-1 px-2 rounded">
                            Supprimer
                          </span>
                          <Trash2 className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {activeTab === 'calendar' && (
              <div className="p-4">
                <BookingCalendar bookings={bookings} />
              </div>
            )}
            
            {activeTab === 'conversations' && (
              <div className="h-full">
                <ConversationManager />
              </div>
            )}
            
            {activeTab === 'admins' && (
              <div className="p-4">
                <AdminManager />
              </div>
            )}
            
            {activeTab === 'blog' && (
              <div className="p-4">
                <BlogManager />
              </div>
            )}
            
            {activeTab === 'settings' && (
              <div className="p-6">
                <h2 className="text-2xl font-semibold mb-4">Paramètres</h2>
                {/* Contenu des paramètres */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;