import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { PartyPopper, Heart, Star, Music, Camera, Users, CheckCircle2, Gift, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function EvenementsSpeciaux() {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ block: 'start', behavior: 'instant' });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Événements Spéciaux VTC Paris | Transport Événementiel | Elite Transfert</title>
        <meta name="description" content="Service VTC premium pour vos événements spéciaux à Paris. Transport mariage, soirées, événements privés. Véhicules luxueux et service personnalisé." />
        <meta name="keywords" content="vtc événementiel paris, transport mariage, location voiture mariage, transport soirée paris, chauffeur événement" />
        <meta property="og:title" content="Événements Spéciaux VTC Paris | Transport Événementiel | Elite Transfert" />
        <meta property="og:description" content="Service de transport haut de gamme pour vos événements spéciaux à Paris. Mariages, soirées, événements privés avec chauffeur professionnel." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elite-transfer.fr/evenements-speciaux" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Événements Spéciaux VTC Paris | Elite Transfert" />
        <meta name="twitter:description" content="Transport VTC premium pour vos événements spéciaux. Service sur mesure, véhicules luxueux, chauffeurs expérimentés." />
        <link rel="canonical" href="https://elite-transfer.fr/evenements-speciaux" />
      </Helmet>

      <div ref={contentRef} className="min-h-screen bg-[#121212] text-white py-16 px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-7xl mx-auto"
        >
          {/* En-tête */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Événements Spéciaux
              <span className="block text-2xl mt-2 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text">
                Des moments inoubliables méritent un service d'exception
              </span>
            </h1>
            <p className="text-gray-300 max-w-3xl mx-auto text-lg">
              Faites de chaque événement un moment mémorable avec notre service de transport VIP. Une expérience sur mesure pour vos célébrations les plus importantes.
            </p>
          </div>

          {/* Types d'Événements */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
            {[
              {
                icon: Heart,
                title: "Mariages",
                description: "Transport élégant pour votre jour J"
              },
              {
                icon: Music,
                title: "Soirées & Galas",
                description: "Arrivez avec style à vos événements"
              },
              {
                icon: Camera,
                title: "Séances Photos",
                description: "Véhicules parfaits pour vos shootings"
              },
              {
                icon: Gift,
                title: "Occasions Spéciales",
                description: "Anniversaires, demandes en mariage..."
              }
            ].map((event, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-white/5 backdrop-blur-lg rounded-xl p-6 hover:bg-white/10 transition-colors"
              >
                <event.icon className="w-12 h-12 text-yellow-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
                <p className="text-gray-300">{event.description}</p>
              </motion.div>
            ))}
          </div>

          {/* Services Spéciaux */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="space-y-6"
            >
              <h2 className="text-3xl font-bold mb-6">Services Personnalisés</h2>
              <div className="space-y-4">
                {[
                  "Chauffeur en tenue de cérémonie",
                  "Véhicule décoré selon vos souhaits",
                  "Accueil personnalisé des invités",
                  "Service de conciergerie dédié",
                  "Planification détaillée de l'itinéraire",
                  "Assistance VIP tout au long de l'événement"
                ].map((service, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <CheckCircle2 className="w-6 h-6 text-yellow-500 flex-shrink-0 mt-1" />
                    <p className="text-gray-300">{service}</p>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-white/5 backdrop-blur-lg rounded-xl p-8"
            >
              <h3 className="text-2xl font-bold mb-6">Notre Engagement</h3>
              <div className="space-y-6">
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Excellence du Service</h4>
                  <p className="text-gray-300">Chaque détail est soigneusement planifié pour assurer une expérience parfaite.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Personnalisation Totale</h4>
                  <p className="text-gray-300">Nous adaptons nos services à vos souhaits et aux spécificités de votre événement.</p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2 text-yellow-500">Moments Mémorables</h4>
                  <p className="text-gray-300">Nous contribuons à créer des souvenirs inoubliables pour vous et vos invités.</p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Galerie d'Événements */}
          <div className="mb-16">
            <h2 className="text-3xl font-bold text-center mb-8">Nos Réalisations</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  image: "/events/wedding.jpg.jpg",
                  title: "Mariage de Luxe",
                  description: "Transport des mariés et des invités VIP"
                },
                {
                  image: "/events/gala.jpg.jpg",
                  title: "Gala Annuel",
                  description: "Service navette pour 200 invités"
                },
                {
                  image: "/events/corporate.jpg.jpg",
                  title: "Événement Corporate",
                  description: "Transport des dirigeants et clients"
                }
              ].map((event, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-white/5 backdrop-blur-lg rounded-xl overflow-hidden"
                >
                  <div className="h-48 overflow-hidden">
                    <img 
                      src={event.image} 
                      alt={event.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
                    <p className="text-gray-300">{event.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Call to Action */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="text-center space-y-6"
          >
            <h2 className="text-3xl font-bold">Réservez Votre Événement</h2>
            <p className="text-gray-300 max-w-3xl mx-auto">
              Faites de votre événement un moment inoubliable avec nos services de transport de luxe sur mesure.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
              <Link to="/">
                <button className="px-8 py-4 bg-yellow-500 text-black rounded-xl font-semibold hover:bg-yellow-400 transition-colors w-full sm:w-auto">
                  Demander Un Devis
                </button>
              </Link>
              <a 
                href="tel:0650288464" 
                className="px-8 py-4 bg-transparent border-2 border-yellow-500 text-yellow-500 rounded-xl font-semibold hover:bg-yellow-500 hover:text-black transition-colors w-full sm:w-auto flex items-center justify-center gap-2"
              >
                <Phone className="w-5 h-5" />
                <span>06 50 28 84 64</span>
              </a>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
