import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, orderBy, onSnapshot, doc, updateDoc, deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { Plus, Edit2, Trash2, Send, Sparkles, Eye, Calendar, Tag } from 'lucide-react';
import { generateImage } from '../../services/imageService';

interface BlogPost {
  id: string;
  title: string;
  content: string;
  createdAt: Date;
  category: string;
  status: 'draft' | 'published';
  image?: string;
  tags?: string[];
  textColor?: string;
  titleColor?: string;
  subtitleColor?: string;
  h1Color?: string;
}

const BlogManager: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("/images/blog/blog-article.jpeg");
  const [textColor, setTextColor] = useState('#333333');
  const [titleColor, setTitleColor] = useState('#111111');
  const [subtitleColor, setSubtitleColor] = useState('#222222');
  const [h1Color, setH1Color] = useState('#007bff');
  const [editingPost, setEditingPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('Initializing blog posts listener...');
    const q = query(collection(db, 'blog_posts'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      try {
        const postsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as BlogPost[];
        
        console.log(`Fetched ${postsData.length} blog posts`);
        setPosts(postsData);
        setLoading(false);
      } catch (error) {
        console.error('Error processing blog posts:', error);
        toast.error('Erreur lors du chargement des articles');
        setLoading(false);
      }
    }, (error) => {
      console.error('Error in blog posts listener:', error);
      toast.error('Erreur de connexion à la base de données');
      setLoading(false);
    });

    return () => {
      console.log('Cleaning up blog posts listener...');
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Toujours utiliser l'image par défaut
    setImageUrl("/images/blog/blog-article.jpeg");
  }, []);

  useEffect(() => {
    const createInitialArticles = async () => {
      const articles = [
        {
          title: "Services VTC Premium : L'Excellence du Transport",
          content: `
            <h2>Découvrez Nos Services VTC Premium</h2>
            <p>Dans l'univers du transport haut de gamme, nos services VTC redéfinissent les standards du luxe et du confort. Explorez notre gamme de prestations exclusives conçues pour répondre à vos exigences les plus élevées.</p>

            <h3>🌟 Nos Services Premium</h3>
            <ul>
              <li><strong>Flotte Exclusive</strong> - Mercedes Classe S, BMW Série 7</li>
              <li><strong>Chauffeurs d'Excellence</strong> - Formation 5 étoiles</li>
              <li><strong>Confort Optimal</strong> - Sièges en cuir, climatisation bi-zone</li>
              <li><strong>Wifi Gratuit</strong> - Restez connecté pendant vos déplacements</li>
            </ul>

            <h3>💼 Services Business</h3>
            <p>Solutions sur mesure pour professionnels :</p>
            <ul>
              <li>Compte entreprise personnalisé</li>
              <li>Facturation simplifiée</li>
              <li>Service de conciergerie 24/7</li>
              <li>Suivi en temps réel</li>
            </ul>

            <h3>✈️ Transferts Aéroport VIP</h3>
            <ul>
              <li>Accueil personnalisé</li>
              <li>Prise en charge des bagages</li>
              <li>Suivi des vols en temps réel</li>
              <li>Service d'attente gratuit</li>
            </ul>

            <h3>🎉 Événements Spéciaux</h3>
            <p>Des moments inoubliables :</p>
            <ul>
              <li>Mariages & Cérémonies</li>
              <li>Soirées VIP</li>
              <li>Tournages & Productions</li>
              <li>Circuits touristiques privés</li>
            </ul>

            <p class="cta">Réservez dès maintenant votre expérience VTC premium et découvrez l'excellence du transport personnalisé.</p>
          `,
          category: "services",
          image: "/images/blog/vtc-luxury.jpg",
          date: new Date().toISOString(),
          tags: ["VTC Premium", "Transport Luxe", "Services Business", "Événements"],
          status: "published",
          createdAt: new Date(),
          h1Color: "#E5C13B",
          titleColor: "#E5C13B",
          subtitleColor: "#CCCCCC",
          textColor: "#FFFFFF"
        },
        {
          title: "Services de Transferts Aéroports : Votre Guide Premium",
          content: `
            <h2>Services de Transferts Aéroports Premium</h2>
            <p>Découvrez nos services de transferts aéroports exclusifs, conçus pour transformer vos trajets en une expérience de luxe sans stress.</p>

            <h3>🌟 Nos Services Signature</h3>
            <ul>
              <li><strong>Accueil VIP</strong> - Service personnalisé dès votre arrivée</li>
              <li><strong>Confort Premium</strong> - Véhicules haut de gamme</li>
              <li><strong>Ponctualité Garantie</strong> - Suivi des vols en temps réel</li>
              <li><strong>Assistance Bagages</strong> - Service porteur inclus</li>
            </ul>

            <h3>✈️ Couverture Complète des Aéroports</h3>
            <p>Services disponibles dans tous les aéroports parisiens :</p>
            <ul>
              <li>Charles de Gaulle (CDG)</li>
              <li>Orly (ORY)</li>
              <li>Le Bourget</li>
              <li>Beauvais (BVA)</li>
            </ul>

            <h3>💼 Services Business</h3>
            <p>Solutions entreprises dédiées :</p>
            <ul>
              <li>Compte professionnel personnalisé</li>
              <li>Tarifs préférentiels</li>
              <li>Facturation centralisée</li>
              <li>Support prioritaire 24/7</li>
            </ul>

            <h3>🎯 Avantages Premium</h3>
            <ul>
              <li>Réservation simple et rapide</li>
              <li>Modification gratuite jusqu'à 24h</li>
              <li>Suivi en temps réel</li>
              <li>Chauffeurs multilingues</li>
            </ul>

            <p class="cta">Réservez maintenant votre transfert premium et commencez votre voyage dans le confort absolu.</p>
          `,
          category: "services",
          image: "/images/blog/airport-guide.jpg",
          date: new Date().toISOString(),
          tags: ["Transferts Aéroport", "Service Premium", "VTC", "Business"],
          status: "published",
          createdAt: new Date(),
          h1Color: "#E5C13B",
          titleColor: "#E5C13B",
          subtitleColor: "#CCCCCC",
          textColor: "#FFFFFF"
        }
      ];

      for (const article of articles) {
        const slug = createSlugFromTitle(article.title);
        const existingDoc = await getDoc(doc(db, 'blog_posts', slug));
        
        if (!existingDoc.exists()) {
          await setDoc(doc(db, 'blog_posts', slug), article);
          console.log(`Article créé : ${article.title}`);
        } else {
          // Mise à jour de l'article existant
          await updateDoc(doc(db, 'blog_posts', slug), article);
          console.log(`Article mis à jour : ${article.title}`);
        }
      }
    };

    createInitialArticles();
  }, []);

  const handleAddTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newTag.trim()) {
      e.preventDefault();
      if (!tags.includes(newTag.trim())) {
        setTags([...tags, newTag.trim()]);
      }
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const generateImageWithAI = async (prompt: string): Promise<string> => {
    console.log('Starting image generation with prompt:', prompt);
    
    const REPLICATE_API_TOKEN = "r8_2qPE5VGwQQAXFjQAWPQEhEKEQAcC4vFxBnzAE";
    const MODEL_VERSION = "stability-ai/sdxl:39ed52f2a78e934b3ba6e2a89f5b1c712de7dfea535525255b1aa35c5565e08b";

    try {
      // Première étape : lancer la génération
      const response = await fetch("https://api.replicate.com/v1/predictions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${REPLICATE_API_TOKEN}`,
        },
        body: JSON.stringify({
          version: MODEL_VERSION,
          input: {
            prompt: `professional photograph of ${prompt}, high quality, 4k, realistic, commercial photography`,
            negative_prompt: "cartoon, illustration, anime, drawing, low quality, blurry, distorted",
            width: 896,
            height: 512,
            num_outputs: 1,
            scheduler: "K_EULER",
            num_inference_steps: 50,
            guidance_scale: 7.5,
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const prediction = await response.json();
      console.log('Generation started, prediction ID:', prediction.id);

      // Deuxième étape : attendre et vérifier le résultat
      let attempts = 0;
      const maxAttempts = 60; // 2 minutes maximum
      
      while (attempts < maxAttempts) {
        const checkResponse = await fetch(
          `https://api.replicate.com/v1/predictions/${prediction.id}`,
          {
            headers: {
              Authorization: `Token ${REPLICATE_API_TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!checkResponse.ok) {
          throw new Error(`HTTP error checking status! status: ${checkResponse.status}`);
        }

        const result = await checkResponse.json();
        console.log('Checking status:', result.status);

        if (result.status === "succeeded" && result.output && result.output[0]) {
          console.log('Image generated successfully:', result.output[0]);
          return result.output[0];
        }

        if (result.status === "failed") {
          throw new Error(`Generation failed: ${result.error}`);
        }

        // Attendre 2 secondes avant la prochaine vérification
        await new Promise(resolve => setTimeout(resolve, 2000));
        attempts++;
      }

      throw new Error("Generation timed out");
    } catch (error) {
      console.error('Error in generateImageWithAI:', error);
      throw error;
    }
  };

  const getCategoryPrompt = (category: string): string => {
    const prompts = {
      'transfert-aeroport': 'luxury black Mercedes S-Class car at modern airport terminal entrance, professional chauffeur service, daytime',
      'location-chauffeur': 'professional chauffeur in suit standing next to luxury black Mercedes-Maybach, upscale hotel entrance',
      'corporate': 'executive black Mercedes V-Class van for business transport, modern office building background',
      'evenements': 'luxury Mercedes S-Class decorated for wedding, elegant venue background, professional photography'
    };
    
    return prompts[category as keyof typeof prompts] || 'luxury car service professional photography';
  };

  const generateUnsplashImage = (keyword: string): string => {
    console.log('Using Unsplash fallback for keyword:', keyword);
    const timestamp = Date.now();
    return `https://source.unsplash.com/featured/896x512/?${encodeURIComponent(keyword)}&t=${timestamp}`;
  };

  const handleGenerateImage = async () => {
    if (!category) {
      toast.error('Veuillez sélectionner une catégorie');
      return;
    }

    setIsGeneratingImage(true);
    try {
      const categoryPrompt = getCategoryPrompt(category);
      console.log('Starting AI image generation with prompt:', categoryPrompt);
      
      toast.loading('Génération de l\'image en cours...', { duration: 60000 });
      const newImageUrl = await generateImage(categoryPrompt);
      
      setImageUrl(newImageUrl);
      toast.success('Image générée avec succès !');
    } catch (error) {
      console.error('Error generating AI image:', error);
      const fallbackUrl = generateUnsplashImage(category);
      setImageUrl(fallbackUrl);
      toast.error('Erreur lors de la génération de l\'image AI, utilisation d\'une image de secours');
    } finally {
      setIsGeneratingImage(false);
    }
  };

  const generateContent = async () => {
    console.log('Starting content generation...');
    if (!title || !category) {
      toast.error('Veuillez spécifier un titre et une catégorie');
      return;
    }

    setIsGenerating(true);
    try {
      // Générer l'image avec l'IA
      const categoryPrompt = getCategoryPrompt(category);
      console.log('Starting AI image generation with prompt:', categoryPrompt);
      
      let imageUrl;
      try {
        imageUrl = await generateImageWithAI(categoryPrompt);
        console.log('Successfully generated AI image:', imageUrl);
      } catch (error) {
        console.error('Error generating AI image:', error);
        imageUrl = generateUnsplashImage(categoryPrompt);
        console.log('Using Unsplash fallback image:', imageUrl);
      }

      const prompt = `Écris un article de blog professionnel sur le thème "${title}" dans la catégorie "${category}". 
      L'article doit être informatif, engageant et optimisé pour le SEO. 
      Utilise un ton professionnel et inclus des sous-titres pertinents.
      L'article doit faire environ 500 mots et être structuré avec une introduction, 2-3 parties principales, et une conclusion.
      Format: HTML avec des balises <h2> pour les titres principaux et <h3> pour les sous-titres.
      Termine l'article avec un appel à l'action pour réserver nos services.
      Suggère également 3 à 5 tags pertinents pour cet article.`;

      console.log('Sending request to OpenAI...');
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 1000,
          temperature: 0.7
        })
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received response from OpenAI');
      const generatedContent = data.choices[0].message.content.trim();
      
      // Extraire les tags suggérés
      const contentParts = generatedContent.split('Tags suggérés:');
      const articleContent = contentParts[0].trim();
      let suggestedTags: string[] = [];
      
      if (contentParts.length > 1) {
        suggestedTags = contentParts[1]
          .split(',')
          .map(tag => tag.trim())
          .filter(tag => tag.length > 0);
        console.log('Generated tags:', suggestedTags);
      }

      const contentWithCTA = `
        <style>
          h1 { color: ${h1Color} !important; }
          h2 { color: ${titleColor} !important; }
          h3 { color: ${subtitleColor} !important; }
          p { color: ${textColor}; }
          .cta-section { 
            background-color: #000000 !important;
            border: 1px solid #E5C13B !important;
          }
          .cta-section h3, .cta-section p {
            color: #E5C13B !important;
          }
        </style>
        ${articleContent}
        <div class="cta-section" style="margin-top: 2rem; padding: 2rem; border-radius: 8px; text-align: center;">
          <h3>Prêt à réserver nos services ?</h3>
          <p style="margin: 1rem 0;">Nous sommes là pour répondre à tous vos besoins en matière de transport de luxe.</p>
          <a href="/" class="cta-button" style="display: inline-block; padding: 1rem 2rem; background-color: #000000; color: #E5C13B; text-decoration: none; border-radius: 4px; margin-top: 1rem; font-weight: 600; border: 2px solid #E5C13B; transition: all 0.3s ease;">
            Réserver maintenant
          </a>
        </div>`;

      setContent(contentWithCTA);
      setTags(suggestedTags);
      
      // Création automatique de l'article
      const docRef = await addDoc(collection(db, 'blog_posts'), {
        title,
        content: contentWithCTA,
        category,
        tags: suggestedTags,
        status: 'published',
        createdAt: new Date(),
        image: "/images/blog/blog-article.jpeg", // Image fixe
        textColor,
        titleColor,
        subtitleColor,
        h1Color
      });

      console.log('Article created successfully with ID:', docRef.id);
      toast.success('Article généré et publié avec succès');
      
      // Reset form
      setTitle('');
      setContent('');
      setCategory('');
      setTags([]);
    } catch (error) {
      console.error('Error generating content:', error);
      toast.error('Erreur lors de la génération du contenu');
    } finally {
      setIsGenerating(false);
    }
  };

  const createSlugFromTitle = (title: string) => {
    return title
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Enlève les accents
      .replace(/[^a-z0-9]+/g, '-') // Remplace les caractères spéciaux par des tirets
      .replace(/^-+|-+$/g, '') // Enlève les tirets au début et à la fin
      .trim();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const slug = createSlugFromTitle(title);
      
      // Vérifie si un article avec ce slug existe déjà
      const existingDoc = await getDoc(doc(db, 'blog_posts', slug));
      if (existingDoc.exists()) {
        toast.error('Un article avec ce titre existe déjà');
        return;
      }

      const newPost = {
        title,
        content,
        category,
        image: "/images/blog/blog-article.jpeg",
        date: new Date().toISOString(),
        h1Color,
        titleColor,
        subtitleColor,
        textColor,
        tags: tags,
        status: 'published',
        createdAt: new Date(),
      };

      // Utilise le slug comme ID du document
      await setDoc(doc(db, 'blog_posts', slug), newPost);
      
      toast.success('Article créé avec succès');
      setTitle('');
      setContent('');
      setCategory('');
      setTags([]);
      setNewTag('');
      
      // Recharge la liste des articles
      const q = query(collection(db, 'blog_posts'), orderBy('createdAt', 'desc'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        try {
          const postsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as BlogPost[];
          
          console.log(`Fetched ${postsData.length} blog posts`);
          setPosts(postsData);
          setLoading(false);
        } catch (error) {
          console.error('Error processing blog posts:', error);
          toast.error('Erreur lors du chargement des articles');
          setLoading(false);
        }
      }, (error) => {
        console.error('Error in blog posts listener:', error);
        toast.error('Erreur de connexion à la base de données');
        setLoading(false);
      });
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error('Erreur lors de la création de l\'article');
    }
  };

  const handleUpdatePost = async (postId: string) => {
    try {
      const newSlug = createSlugFromTitle(title);
      
      // Si le titre a changé, vérifie si le nouveau slug n'existe pas déjà
      if (newSlug !== postId) {
        const existingDoc = await getDoc(doc(db, 'blog_posts', newSlug));
        if (existingDoc.exists()) {
          toast.error('Un article avec ce titre existe déjà');
          return;
        }
      }

      const updatedPost = {
        title,
        content,
        category,
        image: "/images/blog/blog-article.jpeg",
        date: new Date().toISOString(),
        h1Color,
        titleColor,
        subtitleColor,
        textColor,
        tags,
        status: 'published',
        updatedAt: new Date(),
      };

      if (newSlug !== postId) {
        // Si le titre a changé, crée un nouveau document et supprime l'ancien
        await setDoc(doc(db, 'blog_posts', newSlug), updatedPost);
        await deleteDoc(doc(db, 'blog_posts', postId));
      } else {
        // Sinon, met simplement à jour le document existant
        await updateDoc(doc(db, 'blog_posts', postId), updatedPost);
      }

      toast.success('Article mis à jour');
      setEditingPost(null);
      setTitle('');
      setContent('');
      setCategory('');
      setTags([]);
      setNewTag('');
      
      // Recharge la liste des articles
      const q = query(collection(db, 'blog_posts'), orderBy('createdAt', 'desc'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        try {
          const postsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as BlogPost[];
          
          console.log(`Fetched ${postsData.length} blog posts`);
          setPosts(postsData);
          setLoading(false);
        } catch (error) {
          console.error('Error processing blog posts:', error);
          toast.error('Erreur lors du chargement des articles');
          setLoading(false);
        }
      }, (error) => {
        console.error('Error in blog posts listener:', error);
        toast.error('Erreur de connexion à la base de données');
        setLoading(false);
      });
    } catch (error) {
      console.error('Error updating post:', error);
      toast.error('Erreur lors de la mise à jour de l\'article');
    }
  };

  const handleEdit = (post: BlogPost) => {
    console.log('Editing post:', post.id);
    setEditingPost(post);
    setTitle(post.title);
    setContent(post.content);
    setCategory(post.category);
    setTags(post.tags || []);
    setTextColor(post.textColor || '#333333');
    setTitleColor(post.titleColor || '#111111');
    setSubtitleColor(post.subtitleColor || '#222222');
    setH1Color(post.h1Color || '#007bff');
  };

  const handleDelete = async (postId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
      try {
        console.log('Deleting post:', postId);
        await deleteDoc(doc(db, 'blog_posts', postId));
        toast.success('Article supprimé');
      } catch (error) {
        console.error('Error deleting post:', error);
        toast.error('Erreur lors de la suppression');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6 bg-gray-50 p-6 rounded-xl">
      <style>
        {`
          .blog-form-image {
            max-width: 300px;
            max-height: 200px;
            object-fit: cover;
            border-radius: 8px;
            margin: 10px 0;
          }
          .blog-preview-image {
            max-width: 150px;
            max-height: 100px;
            object-fit: cover;
            border-radius: 4px;
          }
        `}
      </style>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">Gestion du Blog</h1>
        <button
          onClick={generateContent}
          disabled={isGenerating || !title || !category}
          className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg hover:from-purple-700 hover:to-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
        >
          <Sparkles className="h-5 w-5" />
          {isGenerating ? 'Génération...' : 'Générer un article'}
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Titre</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors duration-200"
              placeholder="Titre de l'article"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Catégorie</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors duration-200"
            >
              <option value="">Sélectionner une catégorie</option>
              <option value="transfert-aeroport">Transfert Aéroport</option>
              <option value="location-chauffeur">Location avec Chauffeur</option>
              <option value="corporate">Service Corporate</option>
              <option value="evenements">Événements Spéciaux</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Couleur du texte
            </label>
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={textColor}
                onChange={(e) => setTextColor(e.target.value)}
                className="h-10 w-20"
              />
              <span className="text-sm" style={{ color: textColor }}>{textColor}</span>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Couleur du H1
            </label>
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={h1Color}
                onChange={(e) => setH1Color(e.target.value)}
                className="h-10 w-20"
              />
              <span className="text-sm" style={{ color: h1Color }}>{h1Color}</span>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Couleur des titres
            </label>
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={titleColor}
                onChange={(e) => setTitleColor(e.target.value)}
                className="h-10 w-20"
              />
              <span className="text-sm" style={{ color: titleColor }}>{titleColor}</span>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Couleur des sous-titres
            </label>
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={subtitleColor}
                onChange={(e) => setSubtitleColor(e.target.value)}
                className="h-10 w-20"
              />
              <span className="text-sm" style={{ color: subtitleColor }}>{subtitleColor}</span>
            </div>
          </div>
        </div>

        <div className="p-4 border rounded bg-white">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Prévisualisation des couleurs</h4>
          <h1 style={{ color: h1Color, fontSize: '2.5rem', fontWeight: 'bold' }}>Exemple de H1</h1>
          <h2 style={{ color: titleColor }}>Exemple de titre</h2>
          <h3 style={{ color: subtitleColor }}>Exemple de sous-titre</h3>
          <p style={{ color: textColor }}>Exemple de texte</p>
          <div className="mt-4 p-4 rounded" style={{ backgroundColor: '#000000', border: '1px solid #E5C13B' }}>
            <h3 style={{ color: '#E5C13B' }}>Section CTA</h3>
            <button
              className="px-6 py-2 mt-2"
              style={{
                backgroundColor: '#000000',
                color: '#E5C13B',
                border: '2px solid #E5C13B',
                borderRadius: '4px',
                fontWeight: '600'
              }}
            >
              Exemple de bouton
            </button>
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Image de l'article
          </label>
          <div className="flex flex-col space-y-4">
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Article preview"
                className="blog-form-image"
              />
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Tags</label>
          <div className="mt-1">
            <div className="flex flex-wrap gap-2 mb-2">
              {tags.map(tag => (
                <span
                  key={tag}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleRemoveTag(tag)}
                    className="ml-1 text-blue-600 hover:text-blue-800"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={handleAddTag}
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors duration-200"
              placeholder="Ajouter un tag (Appuyez sur Entrée)"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Contenu</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={10}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors duration-200"
            placeholder="Contenu de l'article"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          {editingPost ? 'Mettre à jour' : 'Créer'}
        </button>
      </form>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {posts.map((post) => (
          <div key={post.id} className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden hover:shadow-md transition-shadow duration-200">
            {post.image && (
              <img
                src={post.image}
                alt={post.title}
                className="blog-preview-image"
              />
            )}
            <div className="p-4 space-y-4">
              <div className="space-y-2">
                <h3 className="text-lg font-medium text-gray-800 line-clamp-2">{post.title}</h3>
                <div className="flex items-center gap-4 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <Calendar className="h-4 w-4" />
                    <span>{new Date(post.createdAt.toDate()).toLocaleDateString()}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Tag className="h-4 w-4" />
                    <span>{post.category}</span>
                  </div>
                </div>
              </div>
              
              <div className="flex items-center justify-between pt-4 border-t border-gray-100">
                <div className="flex gap-2">
                  <button
                    onClick={() => handleEdit(post)}
                    className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors duration-200"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(post.id)}
                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors duration-200"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
                <a
                  href={`/blog/${post.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 text-sm text-gray-500 hover:text-blue-600 transition-colors duration-200"
                >
                  <Eye className="h-4 w-4" />
                  Voir
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogManager;
