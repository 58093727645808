import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useModalStore } from '../store/modalStore';
import HeroSlider from '../components/HeroSlider';
import About from '../components/About';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Contact from '../components/Contact';
import Destinations from '../components/Destinations';
import ReservationModal from '../components/ReservationModal';
import ScrollToTop from '../components/ScrollToTop';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Star, CheckCircle2, ArrowRight } from 'lucide-react';

interface HomePageProps {
}

const HomePage: React.FC<HomePageProps> = () => {
  const { isBookingModalOpen, openBookingModal, closeBookingModal } = useModalStore();
  const [formData, setFormData] = useState({
    pickup: '',
    dropoff: '',
    date: '',
    time: '',
    pickupPlaceId: '',
    dropoffPlaceId: ''
  });

  const handleBookNow = (data: typeof formData) => {
    setFormData(data);
    openBookingModal();
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Elite Transfert",
    "image": "https://elite-transfer.fr/images/elite-transfer-logo.png",
    "description": "Service VTC haut de gamme à Paris et en Île-de-France. Transport premium avec chauffeur privé.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Paris",
      "addressLocality": "Paris",
      "addressRegion": "Île-de-France",
      "postalCode": "75000",
      "addressCountry": "FR"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 48.8566,
      "longitude": 2.3522
    },
    "url": "https://elite-transfer.fr",
    "telephone": "+33650288464",
    "priceRange": "€€€",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "sameAs": [
      "https://www.facebook.com/elitetransfert",
      "https://www.instagram.com/elitetransfert",
      "https://www.linkedin.com/company/elite-transfert"
    ]
  };

  return (
    <div className="bg-white dark:bg-neutral-900 transition-colors duration-300">
      <Helmet>
        <title>Elite Transfert | Service VTC de Luxe à Paris et Île-de-France</title>
        <meta name="description" content="Elite Transfert, votre service VTC haut de gamme à Paris. Location de voiture avec chauffeur, transferts aéroport, événements spéciaux et service corporate. Transport premium 24/7." />
        <meta name="keywords" content="vtc paris, chauffeur privé, transport luxe, location voiture chauffeur, transfert aéroport paris, service corporate" />
        <meta name="author" content="Elite Transfert" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        
        <meta property="og:title" content="Elite Transfert | Service VTC Premium à Paris" />
        <meta property="og:description" content="Service de transport VTC haut de gamme à Paris. Chauffeurs professionnels, véhicules luxueux, service 24/7. Réservation en ligne simple et rapide." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elite-transfer.fr" />
        <meta property="og:image" content="https://elite-transfer.fr/images/elite-transfer-og.jpg" />
        <meta property="og:site_name" content="Elite Transfert" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Elite Transfert | VTC Premium Paris" />
        <meta name="twitter:description" content="Service VTC haut de gamme à Paris. Transport de luxe, chauffeurs professionnels, disponible 24/7." />
        <meta name="twitter:image" content="https://elite-transfer.fr/images/elite-transfer-twitter.jpg" />
        
        <link rel="canonical" href="https://elite-transfer.fr" />
        
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>

      <main className="scroll-smooth">
        <HeroSlider onBookNow={handleBookNow} />
        <section id="about" className="scroll-mt-16">
          <About />
        </section>
        <section id="services" className="scroll-mt-16">
          <Services />
        </section>
        <section id="destinations" className="scroll-mt-16">
          <Destinations />
        </section>
        <section id="testimonials" className="scroll-mt-16">
          <Testimonials />
        </section>
        <section id="contact" className="scroll-mt-16">
          <Contact />
        </section>
        <FAQ />
        {isBookingModalOpen && (
          <ReservationModal
            isOpen={isBookingModalOpen}
            onClose={closeBookingModal}
            formData={formData}
          />
        )}
      </main>
      <ScrollToTop />
    </div>
  );
};

export default HomePage;