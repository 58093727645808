import React, { useState } from 'react';
import { 
  startOfMonth, 
  endOfMonth, 
  eachDayOfInterval, 
  format, 
  isToday, 
  getDay, 
  addMonths, 
  subMonths,
  isSameMonth,
  isAfter,
  isBefore,
  addDays 
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { 
  MapPin, 
  Clock, 
  User, 
  Car, 
  CreditCard, 
  Calendar, 
  CheckCircle2,
  ChevronLeft,
  ChevronRight,
  CalendarDays
} from 'lucide-react';

interface Booking {
  id: string;
  scheduledDate: string;
  scheduledTime: string;
  firstName: string;
  lastName: string;
  status: string;
  pickup: {
    address: string;
  };
  dropoff: {
    address: string;
  };
  vehicle: {
    name: string;
  };
  price: number;
}

interface BookingCalendarProps {
  bookings: Booking[];
}

const BookingCalendar: React.FC<BookingCalendarProps> = ({ bookings }) => {
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(today);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const startDay = getDay(monthStart);
  const adjustedStartDay = startDay === 0 ? 6 : startDay - 1;
  
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });
  const emptyDays = Array(adjustedStartDay).fill(null);
  const allDays = [...emptyDays, ...daysInMonth];

  const goToPreviousMonth = () => setCurrentDate(subMonths(currentDate, 1));
  const goToNextMonth = () => setCurrentDate(addMonths(currentDate, 1));
  const goToToday = () => setCurrentDate(today);

  // Grouper les réservations par date
  const bookingsByDate = bookings.reduce((acc, booking) => {
    const date = booking.scheduledDate;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(booking);
    return acc;
  }, {} as Record<string, Booking[]>);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'text-green-400';
      case 'pending':
        return 'text-yellow-400';
      case 'cancelled':
        return 'text-red-400';
      default:
        return 'text-gray-400';
    }
  };

  const getStatusBg = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'bg-green-400/10';
      case 'pending':
        return 'bg-yellow-400/10';
      case 'cancelled':
        return 'bg-red-400/10';
      default:
        return 'bg-gray-400/10';
    }
  };

  return (
    <div className="relative">
      <div className="bg-[#1B254B] rounded-xl shadow-lg p-2 sm:p-4 md:p-6">
        {/* En-tête du calendrier */}
        <div className="mb-4 md:mb-6 flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <h2 className="text-xl md:text-2xl font-bold text-white capitalize">
              {format(currentDate, 'MMMM yyyy', { locale: fr })}
            </h2>
            {!isSameMonth(currentDate, today) && (
              <button
                onClick={goToToday}
                className="flex items-center px-3 py-1 text-sm bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20 transition-colors mt-2 sm:mt-0"
              >
                <CalendarDays size={16} className="mr-2" />
                Aujourd'hui
              </button>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={goToPreviousMonth}
              className="p-2 rounded-lg hover:bg-blue-500/10 text-gray-400 hover:text-white transition-colors"
            >
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={goToNextMonth}
              className="p-2 rounded-lg hover:bg-blue-500/10 text-gray-400 hover:text-white transition-colors"
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>

        {/* Grille du calendrier */}
        <div className="grid grid-cols-7 gap-1 sm:gap-2 md:gap-4">
          {/* En-têtes des jours */}
          {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day) => (
            <div
              key={day}
              className="p-1 sm:p-2 text-center font-medium text-gray-300 text-xs sm:text-sm"
            >
              {day}
            </div>
          ))}

          {/* Jours du mois */}
          {allDays.map((date, index) => {
            if (!date) {
              return <div key={`empty-${index}`} className="min-h-[80px] sm:min-h-[100px]" />;
            }

            const dateStr = format(date, 'yyyy-MM-dd');
            const dayBookings = bookingsByDate[dateStr] || [];
            const isCurrentDay = isToday(date);
            const isPast = isBefore(date, today) && !isToday(date);

            return (
              <div
                key={dateStr}
                className={`min-h-[80px] sm:min-h-[100px] p-1 sm:p-2 rounded-xl ${
                  isCurrentDay 
                    ? 'bg-blue-500/20 ring-2 ring-blue-500' 
                    : isPast
                    ? 'bg-[#2A3466]/50'
                    : 'bg-[#2A3466]'
                } hover:bg-blue-500/10 transition-all duration-200 cursor-pointer`}
              >
                <div className="flex justify-between items-start">
                  <span className={`text-xs sm:text-sm font-bold ${
                    isCurrentDay 
                      ? 'text-blue-400' 
                      : isPast
                      ? 'text-gray-400'
                      : 'text-white'
                  }`}>
                    {format(date, 'd')}
                  </span>
                  {dayBookings.length > 0 && (
                    <span className="px-1.5 sm:px-2 py-0.5 text-xs rounded-full bg-blue-500/20 text-blue-400 font-medium">
                      {dayBookings.length}
                    </span>
                  )}
                </div>

                {/* Liste des réservations du jour */}
                <div className="mt-2 space-y-1">
                  {dayBookings.map((booking) => (
                    <div
                      key={booking.id}
                      onClick={() => setSelectedBooking(booking)}
                      className="text-xs sm:text-sm p-1.5 rounded-lg bg-blue-500/10 text-blue-300 cursor-pointer hover:bg-blue-500/20 transition-colors duration-200"
                    >
                      {booking.scheduledTime} - {booking.firstName}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Pop-up des détails de réservation */}
      {selectedBooking && (
        <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50 backdrop-blur-sm p-4">
          <div className="bg-[#1B254B] p-4 sm:p-6 md:p-8 rounded-xl shadow-2xl w-full max-w-md m-4 border border-blue-500/20">
            <div className="flex justify-between items-start mb-6">
              <h3 className="text-xl md:text-2xl font-bold text-white">
                Détails de la réservation
              </h3>
              <button
                onClick={() => setSelectedBooking(null)}
                className="text-gray-400 hover:text-white transition-colors"
              >
                ✕
              </button>
            </div>

            <div className="space-y-4">
              {/* Status Badge */}
              <div className={`inline-flex items-center px-3 py-1 rounded-full ${getStatusBg(selectedBooking.status)} ${getStatusColor(selectedBooking.status)}`}>
                <CheckCircle2 size={16} className="mr-2" />
                <span className="font-medium capitalize">{selectedBooking.status}</span>
              </div>

              {/* Client Info */}
              <div className="flex items-center text-gray-300 bg-blue-500/5 p-3 rounded-lg">
                <User className="mr-3 text-blue-400" size={20} />
                <div>
                  <p className="font-medium text-white">{selectedBooking.firstName} {selectedBooking.lastName}</p>
                </div>
              </div>

              {/* Date et Heure */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div className="flex items-center text-gray-300 bg-blue-500/5 p-3 rounded-lg">
                  <Calendar className="mr-3 text-blue-400" size={20} />
                  <div>
                    <p className="text-sm text-gray-400">Date</p>
                    <p className="font-medium text-white">{format(new Date(selectedBooking.scheduledDate), 'dd/MM/yyyy')}</p>
                  </div>
                </div>
                <div className="flex items-center text-gray-300 bg-blue-500/5 p-3 rounded-lg">
                  <Clock className="mr-3 text-blue-400" size={20} />
                  <div>
                    <p className="text-sm text-gray-400">Heure</p>
                    <p className="font-medium text-white">{selectedBooking.scheduledTime}</p>
                  </div>
                </div>
              </div>

              {/* Adresses */}
              <div className="space-y-3">
                <div className="flex items-start text-gray-300 bg-blue-500/5 p-3 rounded-lg">
                  <MapPin className="mr-3 text-blue-400 mt-1" size={20} />
                  <div>
                    <p className="text-sm text-gray-400">Départ</p>
                    <p className="font-medium text-white">{selectedBooking.pickup.address}</p>
                  </div>
                </div>
                <div className="flex items-start text-gray-300 bg-blue-500/5 p-3 rounded-lg">
                  <MapPin className="mr-3 text-blue-400 mt-1" size={20} />
                  <div>
                    <p className="text-sm text-gray-400">Arrivée</p>
                    <p className="font-medium text-white">{selectedBooking.dropoff.address}</p>
                  </div>
                </div>
              </div>

              {/* Véhicule et Prix */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div className="flex items-center text-gray-300 bg-blue-500/5 p-3 rounded-lg">
                  <Car className="mr-3 text-blue-400" size={20} />
                  <div>
                    <p className="text-sm text-gray-400">Véhicule</p>
                    <p className="font-medium text-white">{selectedBooking.vehicle.name}</p>
                  </div>
                </div>
                <div className="flex items-center text-gray-300 bg-blue-500/5 p-3 rounded-lg">
                  <CreditCard className="mr-3 text-blue-400" size={20} />
                  <div>
                    <p className="text-sm text-gray-400">Prix</p>
                    <p className="font-medium text-white">{selectedBooking.price}€</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingCalendar;
